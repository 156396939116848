import { IconButton, TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

const TableCellWin = ({ disabledField, IconWin, toolTipWin, row, handleClickOpenDialog, setSelectedRow, index }) => {
    const theme = useTheme();
    return (
        <TableCell align="center" sx={{ p: 0 }} style={{ textAlign: 'center' }} size="small">
            {typeof disabledField === 'undefined' || !row[disabledField] ? (
                <Tooltip title={toolTipWin ? <FormattedMessage id={toolTipWin} /> : 'Define toolTipWin properties'}>
                    <IconButton
                        style={{ color: theme.palette.primary.main }}
                        size="small"
                        disabled={row.disable_add}
                        onClick={() => {
                            console.log('on click on icon ', index);
                            handleClickOpenDialog(index);
                            setSelectedRow(row);
                        }}
                    >
                        <IconWin sx={{ fontSize: '1.3rem' }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <IconButton
                    style={{ color: theme.palette.primary.gray }}
                    size="small"
                    disabled={row[disabledField]}
                    onClick={() => {
                        console.log('on click on icon ', index);
                        handleClickOpenDialog(index);
                        setSelectedRow(row);
                    }}
                >
                    <IconWin sx={{ fontSize: '1.3rem' }} />
                </IconButton>
            )}
        </TableCell>
    );
};

TableCellWin.propTypes = {
    row: PropTypes.object.isRequired,
    handleClickOpenDialog: PropTypes.func.isRequired,
    setSelectedRow: PropTypes.func.isRequired,
    toolTipWin: PropTypes.string,
    IconWin: PropTypes.element,
    index: PropTypes.number,
    disabledField: PropTypes.string
};

export default TableCellWin;
