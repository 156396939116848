import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { Del } from 'helper/axiosHelper';

const DeleteRecord = ({ row, fns, dialogObj, onClose, forceRefresh }) => {
    const dispatch = useDispatch();
    const [deleteRow, setDeleteRow] = useState(row);
    const handleDelete = () => {
        Del(`${dialogObj.api}?${dialogObj.pk}=${deleteRow.id}`)
            .then((res) => {
                if (res.data) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.success ? res.data.success : 'Delete successfuly',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    forceRefresh();
                }
            })
            .catch((err) => {
                if (err)
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: err.error,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
            });
    };

    useEffect(() => {
        fns({
            delFn: () => {
                handleDelete();
            },
            cancelFn: () => {
                onClose();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fns]);

    useEffect(() => {
        setDeleteRow(row);
    }, [dialogObj, row]);

    return <div>{dialogObj.message ? <FormattedMessage id={dialogObj.message} /> : <FormattedMessage id="delete_message" />} </div>;
};

DeleteRecord.propTypes = {
    row: PropTypes.object.isRequired,
    fns: PropTypes.func.isRequired,
    dialogObj: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    forceRefresh: PropTypes.func.isRequired
};

export default DeleteRecord;
