import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';

const ButtonCust = ({ onChange, style, icon, ...rest }) => {
    const [inputStyle, setInputStyle] = useState({});

    const theme = useTheme();

    useEffect(() => {
        // default style
        let TempStyle = {
            backgroundColor: rest.type === 'danger' ? theme.palette.error.main : theme.palette.primary.main,
            color: theme.palette.primary.light,
            marginRight: '5px',
            height: '37px'
        };
        // this is to check the style in case of custom styles
        if (style) {
            TempStyle = { ...TempStyle, ...style };
        }
        setInputStyle(TempStyle);
    }, [style]);

    return (
        <Button
            className="buttonCust"
            size="small"
            variant="contained"
            startIcon={icon}
            onClick={() => {
                if (onChange) onChange();
            }}
            inputRef={rest.ref}
            {...rest}
            style={inputStyle}
        >
            <FormattedMessage id={rest.id} />
        </Button>
    );
};

export default ButtonCust;
