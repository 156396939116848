// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconList } from '@tabler/icons';

// constant
const icons = {
    IconList
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const UserManagement = {
    id: 'UserManagement',
    title: <FormattedMessage id="UserManagement" />,
    type: 'group',
    children: [
        {
            id: 'user-management',
            title: <FormattedMessage id="user-management" />,
            type: 'collapse',
            icon: icons.IconList,
            children: [
                {
                    id: 'staff',
                    title: <FormattedMessage id="staff_page" />,
                    type: 'item',
                    url: '/staff',
                    breadcrumbs: true
                },
                {
                    id: 'students',
                    title: <FormattedMessage id="students_page" />,
                    type: 'item',
                    url: '/students',
                    breadcrumbs: true
                },
                {
                    id: 'administrative',
                    title: <FormattedMessage id="administrative_levels_page" />,
                    type: 'item',
                    url: '/administrative-hierarchy',
                    breadcrumbs: true
                },
                {
                    id: 'parents',
                    title: <FormattedMessage id="parents_page" />,
                    type: 'item',
                    url: '/parents',
                    breadcrumbs: true
                },
                {
                    id: 'functionalCat',
                    title: <FormattedMessage id="functional_categories_page" />,
                    type: 'item',
                    url: '/functional-categories',
                    breadcrumbs: true
                },
                {
                    id: 'functionalCatAssign',
                    title: <FormattedMessage id="functional_categories_assign_page" />,
                    type: 'item',
                    url: '/assign-functional-categories',
                    breadcrumbs: true
                },
                {
                    id: 'assignSections',
                    title: <FormattedMessage id="assign_section_page" />,
                    type: 'item',
                    url: '/assign-sections',
                    breadcrumbs: true
                },
                {
                    id: 'users',
                    title: <FormattedMessage id="users_page" />,
                    type: 'item',
                    url: '/users',
                    breadcrumbs: true
                },
                {
                    id: 'roles',
                    title: <FormattedMessage id="roles_page" />,
                    type: 'item',
                    url: '/roles',
                    breadcrumbs: true
                },
                {
                    id: 'deletedStaff',
                    title: <FormattedMessage id="deleted_staff_page" />,
                    type: 'item',
                    url: '/deletedStaff',
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default UserManagement;
