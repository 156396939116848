import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
// import { FirebaseProvider } from 'contexts/FirebaseContext';
import { JWTProvider } from 'contexts/JWTContext';
import LoaderCust from 'ui-component/extended/LoaderCust';
import { useEffect } from 'react';
import { getTranslationJson } from 'utils/TranslationFunc';
// import { Auth0Provider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatchTrans = useDispatch();

    useEffect(async () => {
        // on refresh the window re get the translation json from backend
        const serviceToken = await localStorage.getItem('serviceToken');
        if (serviceToken) {
            getTranslationJson(customization.locale, dispatchTrans);
        }
    }, []);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                // console.log('registrations', registrations);
                // eslint-disable-next-line no-restricted-syntax
                for (const registration of registrations) {
                    //  console.log('registration', registration);
                    // keep only serviceWorker which scope is /disable-service-worker/, The purpose is to make serviceWorker useless
                    if (registration.scope.includes('/disable-service-worker/') === false) {
                        registration.unregister();
                    }
                }
            });
            // clear cache of service worker
            caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
        }
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <LoaderCust ref={(ref) => LoaderCust.setRef(ref)} />
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                <RTLLayout>
                    <Locales>
                        <NavigationScroll>
                            <JWTProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </JWTProvider>
                        </NavigationScroll>
                    </Locales>
                </RTLLayout>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
