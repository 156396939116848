import { FormControl, FormHelperText, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line react/prop-types
const TextFieldCust = ({ value, onChange, style, ref, ...rest }) => {
    const [state, setState] = useState('');
    const [inputStyle, setInputStyle] = useState({});

    const [classStyle, setClassStyle] = useState('');

    useEffect(() => {
        setState(value || '');
    }, [value]);

    useEffect(() => {
        // default style
        let TempStyle = {
            fontSize: '14px',
            marginRight: '5px'
        };

        // this is to check the style in case of custom styles
        if (style) {
            TempStyle = { ...TempStyle, ...style };
        }
        setInputStyle(TempStyle);
    }, [style]);

    useEffect(() => {
        setClassStyle(!state && rest.required ? 'error' : '');
    }, [state, rest.required]);

    useEffect(() => {
        setClassStyle('');
    }, []);
    return (
        <div className={`TextField ${classStyle}`}>
            <FormControl fullWidth {...rest} className="TextFieldControl">
                <TextField
                    size="small"
                    className={`TextFieldCust ${classStyle}`}
                    label={<FormattedMessage id={rest.id} />}
                    value={state}
                    inputRef={ref}
                    required={rest.required}
                    name={rest.name}
                    multiline={!!(rest.minrows > 0 || rest.maxrows > 0)}
                    minRows={rest.minrows ? rest.minrows : 0}
                    maxRows={rest.maxrows ? rest.maxrows : 0}
                    onChange={(e) => {
                        setState(e.target.value);
                        if (!e.target.value && rest.required) {
                            setClassStyle('error');
                        } else {
                            if (onChange) onChange(e);
                            setClassStyle(' ');
                        }
                    }}
                    type="text"
                    {...rest}
                    style={inputStyle}
                    aria-invalid={!state && rest.required ? 'true' : 'false'}
                />
                <FormHelperText className={` ${classStyle}`}>
                    {classStyle === 'error' ? <FormattedMessage id="field_is_required" /> : rest.text?.rest.text}
                </FormHelperText>
            </FormControl>
        </div>
    );
};

export default TextFieldCust;
