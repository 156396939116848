/* eslint-disable prettier/prettier */
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SelectAutoCompleteAsync from 'ui-component/GenericComponents/SelectAutoCompleteAsync';
import SelectLabel from 'ui-component/GenericComponents/SelectLabel';
import TextFieldCust from 'ui-component/GenericComponents/TextFieldCust';
import Stepperaction from 'ui-component/StepperWizard/StepperAction';

// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

const AddressInfo = ({ state, setState, handleNext, handleBack, setErrorIndex, areas, governorates, counties }) => {
    const [initValues, setInitValues] = useState({});
    const initFormikValues = () => {
        setInitValues({
            student_street: state.student_street,
            student_building: state.student_building,
            student_floor: state.student_floor,
            student_governorate: state.student_governorate,
            county_id: state.county_id,
            area_id: state.area_id
        });
    };
    useEffect(() => {
        if (state !== undefined && state.id > 0) {
            initFormikValues();
        }
    }, [state]);

    useEffect(() => {
        initFormikValues();
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={(values) => {
                const newState = {
                    student_street: values.student_street,
                    student_building: values.student_building,
                    student_floor: values.student_floor,
                    student_governorate: values.student_governorate,
                    county_id: values.county_id,
                    area_id: values.area_id
                };
                setState(newState);
                handleNext();
            }}
        >
            {({ values, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <Field
                            required
                            id="student_governorate"
                            name="student_governorate"
                            value={values.student_governorate}
                            style={{ width: '250px' }}
                            option={governorates}
                            data="id"
                            display="label"
                            as={SelectLabel}
                        />

                        <Field
                            required
                            id="county_id"
                            name="county_id"
                            value={values.county_id}
                            style={{ width: '250px' }}
                            option={counties}
                            data="id"
                            display="county_en_name"
                            as={SelectLabel}
                        />
                        <Field
                            required
                            id="area_id"
                            name="area_id"
                            value={values.area_id}
                            acWidth="250px"
                            option={areas}
                            data="id"
                            display="area_en_name"
                            as={SelectAutoCompleteAsync}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div className="row">
                        <Field
                            required
                            id="student_street"
                            name="student_street"
                            value={values.student_street}
                            style={{ width: '250px', marginRight: '5px' }}
                            as={TextFieldCust}
                        />

                        <Field
                            required
                            id="student_building"
                            name="student_building"
                            value={values.student_building}
                            style={{ width: '250px' }}
                            as={TextFieldCust}
                        />

                        <Field
                            required
                            id="student_floor"
                            name="student_floor"
                            value={values.student_floor}
                            style={{ width: '250px' }}
                            as={TextFieldCust}
                        />
                    </div>
                    <Stepperaction
                        handleBack={handleBack}
                        setErrorIndex={() => {
                            setErrorIndex(2);
                        }}
                    />
                </form>
            )}
        </Formik>
    );
};

AddressInfo.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    handleNext: PropTypes.func,
    setErrorIndex: PropTypes.func,
    handleBack: PropTypes.func
};

export default AddressInfo;
