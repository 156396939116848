// Function to sort alphabetically an array of objects by some specific key.

import { FormattedMessage } from 'react-intl';
import { SNACKBAR_OPEN } from 'store/actions';
import { Post, Put } from 'helper/axiosHelper';

// eslint-disable-next-line import/prefer-default-export
export const dynamicSort = (property) => {
    let sortOrder = 1;

    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }

    // eslint-disable-next-line func-names
    return function (a, b) {
        if (sortOrder === -1) {
            return b[property].localeCompare(a[property]);
        }
        return a[property].localeCompare(b[property]);
    };
};

export const numberWithCommas = (x) => {
    if (x) return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    return 0.0;
};

export const upsertApi = (pk, data, apiUpdate, apiCreate, dispatch, forceRefresh = () => {}, onSuccess = () => {}) => {
    if (pk) {
        Put(apiUpdate, data)
            .then((res) => {
                if (res) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.success ? res.data.success : 'Edit successfuly',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    forceRefresh();
                    if (onSuccess) onSuccess();
                }
            })
            .catch((err) => {
                if (err)
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: err.error,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
            });
    } else {
        Post(apiCreate, data)
            .then((res) => {
                if (res.data) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.success ? res.data.success : 'Created successfuly',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    forceRefresh();
                    if (onSuccess) onSuccess();
                }
            })
            .catch((err) => {
                if (err)
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: err.error,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
            });
    }
};
