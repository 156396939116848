/* eslint-disable camelcase */
// third-party
import { Chance } from 'chance';
// eslint-disable-next-line import/no-cycle
import AxiosInstance, { Post } from 'helper/axiosHelper';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import accountReducer from 'store/accountReducer';
// reducer - state management
import { LOGIN, LOGOUT, UPDATE_MESSAGES } from 'store/actions';
// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
// eslint-disable-next-line import/no-cycle
import { getTranslationJson } from 'utils/TranslationFunc';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken, user_id) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        localStorage.setItem('user_id', user_id);
        AxiosInstance.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        AxiosInstance.defaults.headers.common.user_id = user_id;
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('user_id');
        delete AxiosInstance.defaults.headers.common.Authorization;
        delete AxiosInstance.defaults.headers.common.user_id;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const dispatchTrans = useDispatch();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const customization = useSelector((state) => state.customization);
    const transaltion = useSelector((state) => state.translation);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const user_id = window.localStorage.getItem('user_id');

                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken, user_id);
                    // const response = await axios.get('/api/account/me');
                    // const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: user_id
                        }
                    });
                } else {
                    dispatch({
                        type: UPDATE_MESSAGES,
                        messages: {}
                    });
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: UPDATE_MESSAGES,
                    messages: {}
                });
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (username, password) =>
        new Promise((resolve, reject) => {
            Post('/auth/login', { username, password })
                .then((response) => {
                    // eslint-disable-next-line camelcase
                    const { access_token, user } = response.data;
                    setSession(access_token, user.id);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: user.id
                        }
                    });
                    getTranslationJson(customization.locale, dispatchTrans);
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log('login JWT coontext', error);
                    reject(error);
                });
        });

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        dispatch({
            type: UPDATE_MESSAGES,
            messages: {}
        });
        setSession(null, null);
        localStorage.clear();
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
