/* eslint-disable prettier/prettier */
import SearchIcon from '@mui/icons-material/Search';
import { Box, Card, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { gridSpacing } from 'store/constant';
import ButtonCust from 'ui-component/GenericComponents/ButtonCust';
import getComponent from 'ui-component/TableList/Filter/getComponent';
import PropTypes from 'prop-types';

// ==============================|| Translation PAGE ||============================== //

const TableFilter = ({ filterObj, defFilter, mainApi, setApiMain, retrieveData = () => {} }) => {
	const theme = useTheme();
	const [ filter, setFilter ] = useState(defFilter);

	const refreshList = (filter) => {
		if (retrieveData) retrieveData(`${mainApi}mainFilter=${JSON.stringify(filter)}`);
		setApiMain(`${mainApi}mainFilter=${JSON.stringify(filter)}`);
	};
	const clearFilter = () => {
		setFilter(defFilter);
		setApiMain(mainApi);
	};

	useEffect(
		() => {
			setFilter(defFilter);
		},
		[ defFilter ]
	);

	const changeInfo = (e) => {
		const newVal = filter;
		if (!e.target.value && e.target.value !== 0) {
			newVal[e.target.name] = null;
		}
		else newVal[e.target.name] = e.target.value;
		setFilter(newVal);
	};

	return (
		<Card
			sx={{
				marginBottom : theme.spacing(gridSpacing),
				border       : '1px solid',
				borderColor  :
					theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
				background   : theme.palette.background.default,
				overflow     : 'initial'
			}}>
			<Box sx={{ p: 2, pl: 2 }} style={{ alignItems: 'center', padding: '10px', paddingTop: '20px' }}>
				<Grid container direction='row' alignItems='center' spacing={1} style={{ margin: '0px' }}>
					{filterObj.map((el, index) => (
						<Grid item style={{ width: '25%', padding: '0px', marginRight: '5px' }} key={index}>
							{getComponent(el, filter, changeInfo)}
						</Grid>
					))}

					<Grid item style={{ padding: '0px', marginTop: '-3px' }}>
						<ButtonCust
							id='search_btn'
							icon={<SearchIcon />}
							onClick={() => {
								refreshList(filter);
							}}
						/>
						<ButtonCust
							type='danger'
							id='clear_btn'
							onClick={() => {
								clearFilter();
								setFilter(defFilter);
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Card>
	);
};

export default TableFilter;

TableFilter.propTypes = {
	filterObj  : PropTypes.array.isRequired,
	defFilter  : PropTypes.object.isRequired,
	mainApi    : PropTypes.string.isRequired,
	setApiMain : PropTypes.func.isRequired
};
