// action - state management
import * as actionTypes from './actions';

const initialState = {
    messages: {}
};

// ==============================|| SNACKBAR REDUCER ||============================== //

const translationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_MESSAGES:
            return {
                ...state,
                messages: action.messages ? action.messages : initialState.messages
            };
        default:
            return state;
    }
};

export default translationReducer;
