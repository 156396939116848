import { useState, useEffect } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/PrintTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import { Button, CardContent, Fab, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { exportToExcel, exportToPdf } from './ToolbarFunc';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, useIntl } from 'react-intl';
import { Get } from 'helper/axiosHelper';
import { useTheme } from '@mui/material/styles';
import ButtonCust from 'ui-component/GenericComponents/ButtonCust';

const TableToolbar = ({
    api,
    totalData,
    handleSearch,
    search,
    columns,
    reportTitle,
    handlePrint,
    handleClickOpenDialog,
    setSelectedRow,
    data,
    ...rest
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const intl = useIntl();
    const theme = useTheme();
    const open = Boolean(anchorEl);

    const getData = async () => {
        let data = [];
        await Get(`${api}&page=-1&pageSize=${totalData}&filter=`)
            .then((response) => {
                if (response.data.data) {
                    data = response.data.data;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        return data;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <CardContent>
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                    {!rest.hideSearch && (
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search"
                            value={search}
                            size="small"
                        />
                    )}

                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <MenuItem
                            onClick={async () => {
                                handleClose();
                                const data = await getData();
                                exportToExcel(intl, data, columns, 2, <FormattedMessage id={reportTitle} />);
                            }}
                        >
                            Download as CSV
                        </MenuItem>
                        <MenuItem
                            onClick={async () => {
                                handleClose();
                                const data = await getData();
                                exportToExcel(intl, data, columns, 1, <FormattedMessage id={reportTitle} />);
                            }}
                        >
                            Download as Excel
                        </MenuItem>
                        <MenuItem
                            onClick={async () => {
                                handleClose();
                                const data = await getData();
                                exportToPdf(intl, data, columns, <FormattedMessage id={reportTitle} />);
                            }}
                        >
                            Download as PDF
                        </MenuItem>
                    </Menu>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    {typeof rest.actionBtn !== 'undefined' && (
                        <ButtonCust
                            id={rest.actionBtn.label}
                            icon={rest.actionBtn.Icon}
                            onClick={() => {
                                rest.actionBtn.func(data);
                            }}
                        />
                    )}
                    {typeof rest.save !== 'undefined' && (
                        <Tooltip title="Save">
                            <Fab
                                style={{ background: theme.palette.primary.main, color: theme.palette.primary.light }}
                                size="small"
                                onClick={() => {
                                    rest.save(data);
                                }}
                                sx={{ boxShadow: 'none', ml: 1, width: 32, height: 32, minHeight: 32 }}
                            >
                                <SaveIcon fontSize="small" />
                            </Fab>
                        </Tooltip>
                    )}
                    {/*  add & dialog */}
                    {!rest.hideAdd && (
                        <Tooltip title="Add">
                            <Fab
                                style={{ background: theme.palette.primary.main, color: theme.palette.primary.light }}
                                size="small"
                                onClick={() => {
                                    handleClickOpenDialog('new');
                                    setSelectedRow({});
                                }}
                                sx={{ boxShadow: 'none', ml: 1, width: 32, height: 32, minHeight: 32 }}
                            >
                                <AddIcon fontSize="small" />
                            </Fab>
                        </Tooltip>
                    )}
                    {!rest.hideExport && (
                        <Tooltip title="Export">
                            <IconButton size="large" onClick={handleClick}>
                                <DownloadIcon
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!rest.hidePrint && (
                        <Tooltip title="Print">
                            <IconButton size="large" onClick={handlePrint}>
                                <PrintIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </CardContent>
    );
};

TableToolbar.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    reportTitle: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    handlePrint: PropTypes.func.isRequired,
    handleClickOpenDialog: PropTypes.func.isRequired,
    setSelectedRow: PropTypes.func.isRequired,
    api: PropTypes.string.isRequired,
    totalData: PropTypes.number
};

export default TableToolbar;
