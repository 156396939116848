/* eslint-disable jsx-a11y/click-events-have-key-events */
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { Get } from 'helper/axiosHelper';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useTheme } from '@mui/material/styles';

const SelectAutoCompleteAsync = ({ onChange, option, value, acWidth, dddw, minMatch, ...rest }) => {
    const theme = useTheme();
    const [state, setState] = useState({});
    const [stateOpt, setStateOpt] = useState([]);
    const [classStyle, setClassStyle] = useState('');
    const [color, setColor] = useState(theme.palette.grey[500]);

    const dataField = rest.data ? rest.data : 'data';
    const displayField = rest.display ? rest.display : 'display';

    // https://www.cluemediator.com/react-select-async-dropdown-using-search-api
    useEffect(() => {
        if (stateOpt) {
            setStateOpt([]);
        }
        setStateOpt(option);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    const restColor = () => {
        setColor(!state && rest.required ? theme.palette.error.main : theme.palette.grey[500]);
    };

    useEffect(() => {
        setClassStyle(!state && rest.required ? 'error' : '');
        restColor();
    }, [state, rest.required]);

    useEffect(() => {
        setTimeout(() => {
            setClassStyle(' ');
            setColor(theme.palette.grey[500]);
        }, 5);
    }, []);

    const customStyles = {
        container: (base) => ({
            ...base,
            marginRight: '5px'
        }),
        control: (base) => ({
            ...base,
            '&:hover': {
                borderColor: color === theme.palette.grey[500] ? theme.palette.grey[600] : color,
                boxShadow: typeof state === 'undefined' && rest.required === 0 ? '0 0 0 1px red' : `0 0 0 0px ${color}`
            },
            minHeight: 40,
            borderRadius: 12,
            borderColor: color,
            boxShadow: typeof state === 'undefined' && rest.required === 0 ? '0 0 0 0px red' : `0 0 0 0px ${color}`,
            background: '#fafafa'
        }),

        dropdownIndicator: (base) => ({
            ...base,
            padding: 1
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: 1
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: 'black'
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px 6px',
            fontWeight: 500
        }),
        input: (base) => ({
            ...base,
            margin: 0,
            padding: 0
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9000
        })
    };

    useEffect(() => {
        if (dddw) {
            Get(dddw).then((result) => {
                const res = result.data;
                setStateOpt(res.data);
            });
        } else {
            setStateOpt(option);
        }
        if (value === null) setState({ dataField: null, displayField: null });
    }, [dddw, value]);

    const filterOptions = (inputValue, include = 0) => {
        console.log('');
        return stateOpt.filter((i) => {
            let value = '';
            if (i[dataField]) {
                value = i[dataField].toString().toLowerCase();
            }

            return (
                i[displayField].toLowerCase().includes(inputValue.toLowerCase()) ||
                (include === 0 && value.toString().toLowerCase().includes(inputValue.toLowerCase()))
            );
        });
    };

    // eslint-disable-next-line consistent-return
    const loadOptions = (inputValue, callback) => {
        if (minMatch) {
            if (inputValue.length < minMatch) {
                return callback([]);
            }
        }
        setTimeout(() => {
            callback(filterOptions(inputValue));
        }, 1000);
    };

    //  const loadOptions = (inputValue) => fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then((res) => res.json());

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (stateOpt && value) {
            // eslint-disable-next-line eqeqeq
            const filter = stateOpt.filter((ele) => ele[dataField] == value);
            setState(filter);
        } else setState(null);
    }, [value, stateOpt]);

    const [topVal, setTopVal] = useState('-5px');

    const displayFieldname = () => {
        setTopVal('-30px');
    };

    const changeColor = () => {
        setColor(!state && rest.required ? theme.palette.error.main : theme.palette.primary.main);
        displayFieldname();
    };

    const displayOnBlur = () => {
        restColor();
        setTopVal(!state ? '-5px' : '-30px');
    };

    useEffect(() => {
        setTopVal(state !== null ? '-30px' : '-5px');
    }, [state]);

    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <SearchIcon />
        </components.DropdownIndicator>
    );

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            style={{ display: 'block', width: acWidth || '100%' }}
            className={`asyncSelect ${classStyle}`}
            onClick={displayFieldname}
            onBlur={displayOnBlur}
            onFocus={changeColor}
        >
            <FormControl
                className={`asyncSelectForm ${classStyle}`}
                fullWidth
                required={rest.required}
                aria-invalid={!state && rest.required ? 'true' : 'false'}
            >
                <InputLabel style={{ top: topVal, fontSize: topVal === '-30px' ? '0.645rem' : '14px', fontWeight: '400' }}>
                    <FormattedMessage id={rest.id} />
                </InputLabel>
                <AsyncSelect
                    // defaultMenuIsOpen
                    components={{ DropdownIndicator }}
                    noOptionsMessage={() => 'Please enter at least 1 character'}
                    placeholder=""
                    styles={customStyles}
                    cacheOptions={false}
                    defaultOptions
                    isClearable
                    closeOnSelect
                    loadOptions={loadOptions}
                    getOptionLabel={(e) => e[displayField]}
                    getOptionValue={(e) => e[dataField]}
                    value={state}
                    onChange={(e, action) => {
                        setState(e);
                        if (action.action === 'clear') {
                            displayFieldname();
                            setColor(rest.required ? theme.palette.error.main : theme.palette.primary.main);
                        } else {
                            setColor(
                                typeof e[dataField] === 'undefined' && rest.required ? theme.palette.error.main : theme.palette.primary.main
                            );
                            if (typeof e[dataField] !== 'undefined' && rest.required) {
                                const divCont = document.getElementsByClassName('css-xsouc3-control')[0];
                                console.log('divCont.style', divCont);
                                if (divCont !== undefined) {
                                    divCont.style.boxShadow = `0 0 0 0px #${theme.palette.primary.main}`;
                                    divCont.style.borderColor = theme.palette.primary.main;
                                }
                            }
                        }

                        let changeVal = {};
                        if (e === null) {
                            changeVal = {
                                ...e,
                                target: {
                                    value: null,
                                    name: rest.name,
                                    label: null
                                }
                            };
                        } else {
                            changeVal = {
                                ...e,
                                target: {
                                    value: typeof e[dataField] !== 'undefined' ? e[dataField] : null,
                                    name: rest.name,
                                    label: e[displayField]
                                }
                            };
                        }
                        if (e && !e[dataField] && rest.required) {
                            setClassStyle('error');
                        } else {
                            if (onChange) onChange(changeVal);
                            setClassStyle(' ');
                        }
                    }}
                />
                <FormHelperText className={` ${classStyle}`}>
                    {classStyle === 'error' ? <FormattedMessage id="field_is_required" /> : rest.text?.rest.text}
                </FormHelperText>
            </FormControl>
        </div>
    );
};

export default SelectAutoCompleteAsync;
