import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// assets
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

// tab content
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box
                    sx={{
                        p: 3
                    }}
                >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// ================================|| UI TABS - SAMPLE ||================================ //

const VerticalTabs = ({ tabs }) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [tabState, setTabState] = useState([]);

    useEffect(() => {
        setTabState(tabs);
    }, [tabs]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={gridSpacing} className="VerticalTabs">
            <Grid item xs={12} sm={4} md={3}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    orientation="vertical"
                    variant="scrollable"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            borderBottom: 'none'
                        },
                        '& button': {
                            // borderRadius: `${customization.borderRadius}px`,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                            minHeight: 'auto',
                            minWidth: '100%',
                            py: 1.5,
                            px: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                            justifyContent: 'flex-start'
                        },
                        '& button.Mui-selected': {
                            color: theme.palette.primary.main,
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                        },
                        '& button > svg': {
                            marginBottom: '0px !important',
                            marginRight: 1.25,
                            marginTop: 1.25,
                            height: 20,
                            width: 20
                        },
                        '& button > div > span': {
                            display: 'block'
                        },
                        '& > div > span': {
                            display: 'none'
                        }
                    }}
                >
                    {tabState.map((tab, index) => (
                        <Tab
                            icon={tab.icon ? <tab.icon /> : ''}
                            label={<FormattedMessage id={tab.label} />}
                            {...a11yProps(index)}
                            className={tab.className ? tab.className : ''}
                            style={{ alignItems: 'self-end' }}
                        />
                    ))}
                </Tabs>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
                {tabState.map((tab, index) => (
                    <TabPanel value={value} index={index} className="tabPanelCust">
                        {tab.component}
                    </TabPanel>
                ))}
            </Grid>
        </Grid>
    );
};

export default VerticalTabs;

VerticalTabs.propTypes = {
    tabs: PropTypes.node.isRequired
};
