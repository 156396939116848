import definitions from './definitions';
import other from './other';
import UserManagement from './userManagement';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [other, definitions, UserManagement]
};

export default menuItems;
