/* eslint-disable no-nested-ternary */
import { TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Chip from 'ui-component/extended/Chip';
import { getComparator, stableSort } from './BodyFunc';
import TableCellActions from './TableCellActions';

const TableBodyCust = ({
    rows,
    order,
    orderBy,
    columns,
    readOnly,
    handleClickOpenDialog,
    orderByType,
    setSelectedRow,
    currentPage,
    pageSize,
    disableRemove,
    disableEdit,
    disableAdd,
    dispNone,
    theme,
    toolTipAdd,
    DialogComponentAdd,
    dialogWin,
    noRepeatAction
}) => {
    const [data, setData] = useState(rows);
    useEffect(() => {
        setData(rows);
    }, [rows]);
    // const rowsPerPage = data.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
    // const emptyRows = currentPage > 0 ? Math.max(0, pageSize - rowsPerPage.length) : 0;

    return (
        <TableBody>
            {data &&
                stableSort(data, getComparator(order, orderBy, orderByType))
                    // .slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize)
                    .map((row, index) => (
                        /** Make sure no display bugs if row isn't an OrderData object */
                        // if (typeof row === 'number') return null;

                        <TableRow hover tabIndex={-1} key={index} style={{ height: 30 }}>
                            {columns.map((column, i) => (
                                <TableCell
                                    key={i}
                                    style={{
                                        width: column.width ? column.width : `${100 / columns.length}%`,
                                        display: column.hidden ? 'none' : ''
                                    }}
                                    align={column.align ? column.align : 'left'}
                                    size="small"
                                >
                                    {column.type === 'button' ? (
                                        <Chip
                                            label={<FormattedMessage id={row[column.id]} />}
                                            size="small"
                                            chipcolor={column.success === row[column.id] ? 'success' : 'orange'}
                                        />
                                    ) : column.render ? (
                                        column.render(row)
                                    ) : column.noRepeat && index > 0 ? (
                                        data[index - 1][column.id] === row[column.id] ? (
                                            ''
                                        ) : (
                                            row[column.id]
                                        )
                                    ) : (
                                        row[column.id]
                                    )}
                                </TableCell>
                            ))}
                            {!readOnly &&
                                dispNone !== 'none' &&
                                (noRepeatAction && index > 0 ? (
                                    data[index - 1][columns[0].id] === row[columns[0].id] ? (
                                        <TableCell />
                                    ) : (
                                        <TableCellActions
                                            disableRemove={disableRemove}
                                            disableAdd={disableAdd}
                                            disableEdit={disableEdit}
                                            row={row}
                                            handleClickOpenDialog={handleClickOpenDialog}
                                            setSelectedRow={setSelectedRow}
                                            key={index}
                                            theme={theme}
                                            toolTipAdd={toolTipAdd}
                                            DialogComponentAdd={DialogComponentAdd}
                                            dialogWin={dialogWin}
                                        />
                                    )
                                ) : (
                                    <TableCellActions
                                        disableRemove={disableRemove}
                                        disableAdd={disableAdd}
                                        disableEdit={disableEdit}
                                        row={row}
                                        handleClickOpenDialog={handleClickOpenDialog}
                                        setSelectedRow={setSelectedRow}
                                        key={index}
                                        theme={theme}
                                        toolTipAdd={toolTipAdd}
                                        DialogComponentAdd={DialogComponentAdd}
                                        dialogWin={dialogWin}
                                    />
                                ))}
                        </TableRow>
                    ))}
            {/* {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: 45 * emptyRows
                    }}
                >
                    <TableCell colSpan={6} />
                </TableRow>
            )} */}
        </TableBody>
    );
};

TableBodyCust.propTypes = {
    rows: PropTypes.array.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired,
    handleClickOpenDialog: PropTypes.func.isRequired,
    orderByType: PropTypes.string,
    setSelectedRow: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    disableRemove: PropTypes.bool,
    disableEdit: PropTypes.bool,
    dispNone: PropTypes.string,
    disableAdd: PropTypes.bool,
    toolTipAdd: PropTypes.string,
    dialogWin: PropTypes.array,
    DialogComponentAdd: PropTypes.func,
    noRepeatAction: PropTypes.bool
};
export default TableBodyCust;
