import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import VerticalTabs from 'ui-component/GenericComponents/VerticalTabs';
import SummaryList from './SummaryList';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Avatar from 'ui-component/extended/Avatar';
import Avatar1 from 'assets/images/logo.png';

const SummaryInfo = ({ row = {} }) => {
    const tabs = [
        {
            icon: AccountBoxIcon,
            label: 'personal_info',
            component: <SummaryList type="1" row={row} label="personal_info" />
        },
        {
            icon: AccountBoxIcon,
            label: 'contact_info',
            component: <SummaryList type="2" row={row} label="contact_info" />
        },
        {
            icon: LocationOnIcon,
            label: 'address_info',
            component: <SummaryList type="3" row={row} label="address_info" />
        },
        {
            icon: PhoneIphoneIcon,
            label: 'academic_info',
            component: <SummaryList type="4" row={row} label="academic_info" />
        },
        {
            icon: PhoneIphoneIcon,
            label: 'user_info',
            component: <SummaryList type="5" row={row} label="user_info" />
        }
    ];
    return (
        <div style={{ width: '100%' }}>
            <Avatar alt="User 1" src={row.student_profile_photo ? row.row : Avatar1} size="xl" style={{ marginLeft: '55px' }} />
            <div style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '45px', marginTop: '15px' }}>{row.parent_full_name}</div>
            <VerticalTabs tabs={tabs} />
        </div>
    );
};

SummaryInfo.propTypes = {
    row: PropTypes.object.isRequired
};

export default SummaryInfo;
