import PropTypes from 'prop-types';
import { useEffect } from 'react';
// third-party
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_MESSAGES } from 'store/actions';

// load locales files
const loadLocaleData = (locale) => {
    switch (locale) {
        case 'fr':
            return import('utils/locales/fr.json');
        case 'ar':
            return import('utils/locales/ar.json');
        case 'en':
            return import('utils/locales/en.json');
        default:
            return import('utils/locales/en.json');
    }
};

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const transaltion = useSelector((state) => state.translation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const transKeys = transaltion.messages;
        if (transKeys && Object.keys(transKeys).length === 0 && Object.getPrototypeOf(transKeys) === Object.prototype) {
            // console.log('get transKeys from file', transKeys);
            loadLocaleData(customization.locale).then((d) => {
                dispatch({
                    type: UPDATE_MESSAGES,
                    messages: d.default
                });
            });
        } else {
            dispatch({
                type: UPDATE_MESSAGES,
                messages: transKeys
            });
        }
    }, [customization.locale]);

    return (
        <>
            {transaltion.messages && (
                <IntlProvider locale={customization.locale} defaultLocale="en" messages={transaltion.messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

Locales.propTypes = {
    children: PropTypes.node
};

export default Locales;
