/* eslint-disable react/sort-comp */
import { CircularProgress, Stack } from '@mui/material';
import { Component } from 'react';

export default class LoaderCust extends Component {
    // eslint-disable-next-line react/sort-comp
    constructor(props) {
        super(props);
        this.state = { hide: true };
    }

    static _ref = null;

    static setRef(ref = {}) {
        // eslint-disable-next-line no-underscore-dangle
        this._ref = ref;
    }

    static show(options = {}) {
        // eslint-disable-next-line no-underscore-dangle
        this._ref.show(options);
    }

    static hide() {
        // eslint-disable-next-line no-underscore-dangle
        this._ref.hide();
    }

    async show(options = {}) {
        this.setState({ hide: false });
    }

    async hide() {
        this.setState({ hide: true });
    }

    // eslint-disable-next-line no-underscore-dangle
    _setState(reducer) {
        return new Promise((resolve) => this.setState(reducer, () => resolve()));
    }

    componentDidMount() {}

    render() {
        return (
            // eslint-disable-next-line react/destructuring-assignment
            !this.state.hide && (
                <div className="Loading">
                    <Stack display="flex" alignItems="center" justifyContent="center" height="100%" backgroundColor="#0606062e">
                        <CircularProgress />
                    </Stack>
                </div>
            )
        );
    }
}
