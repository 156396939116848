import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import { FormattedMessage } from 'react-intl';
import FlagIcon from '@mui/icons-material/Flag';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';
import MapIcon from '@mui/icons-material/Map';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PushPinIcon from '@mui/icons-material/PushPin';
import StreetviewIcon from '@mui/icons-material/Streetview';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import WcIcon from '@mui/icons-material/Wc';
import CakeIcon from '@mui/icons-material/Cake';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClassIcon from '@mui/icons-material/Class';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import SchoolIcon from '@mui/icons-material/School';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import KeyIcon from '@mui/icons-material/Key';

const SummaryList = ({ row = {}, type, label }) => {
    const [state, setState] = useState([{}]);

    useEffect(() => {
        console.log('row', row);
        setState(row);
    }, [row]);

    const RenderRow = ({ Icon, label, text }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <div style={{ marginRight: '5px' }}>
                <Icon sx={{ fontSize: '1rem' }} />
            </div>
            <div style={{ width: '170px', fontSize: '15px', paddingBottom: '4px' }}>
                <FormattedMessage id={label} />
            </div>
            {text !== null && <div>{` ${text}`} </div>}
        </div>
    );

    return (
        <div style={{ width: '600px', height: '450px', overflowY: 'auto', position: 'relative', top: '-145px', background: '#fbfbfb' }}>
            <div className="summaryTitle">
                <FormattedMessage id={label} />
            </div>
            {type === '1' && (
                <>
                    <RenderRow Icon={PersonIcon} text={state.student_full_name} label="full_name" />
                    <RenderRow Icon={WcIcon} text={state.student_gender} label="gender" />
                    <RenderRow Icon={CakeIcon} text={state.student_birth_date} label="student_birth_date" />
                    <RenderRow Icon={FlagIcon} text={state.nationality_en} label="nationality" />
                    <RenderRow Icon={BloodtypeIcon} text={state.student_blood_type} label="blood_type" />
                    <RenderRow Icon={ManIcon} text={state.parent_full_name} label="father_name" />
                    <RenderRow Icon={FavoriteIcon} text={state.father_alive > 0 ? 'Yes' : 'No'} label="father_alive" />
                    <RenderRow Icon={WomanIcon} text={state.mother_full_name} label="mother_name" />
                    <RenderRow Icon={FavoriteIcon} text={state.wife_alive > 0 ? 'Yes' : 'No'} label="wife_alive" />
                    <RenderRow Icon={MonitorHeartIcon} text={state.parent_marital_status} label="parent_marital_status" />
                </>
            )}
            {type === '2' && (
                <>
                    <RenderRow Icon={PersonIcon} text={state.parent_responsibility} label="parent_responsibility" />
                    <RenderRow Icon={PhoneIphoneIcon} text={state.student_mobile_number} label="student_mobile_number" />
                    <RenderRow Icon={PhoneEnabledIcon} text={state.parent?.parent_telephone_number} label="phone_number" />
                    <RenderRow Icon={PhoneIphoneIcon} text={state.parent?.parent_mobile_number} label="father_mobile_number" />
                    <RenderRow Icon={PhoneIphoneIcon} text={state.mother?.wife_mobile_number} label="mother_mobile_number" />
                </>
            )}
            {type === '3' && (
                <>
                    <RenderRow Icon={MapIcon} text={state.student_governorate} label="governorate" />
                    <RenderRow Icon={PinDropIcon} text={state.county_en} label="county_id" />
                    <RenderRow Icon={PushPinIcon} text={state.area_en} label="area_id" />
                    <RenderRow Icon={StreetviewIcon} text={state.student_street} label="street" />
                    <RenderRow Icon={ApartmentIcon} text={state.student_building} label="building" />
                    <RenderRow Icon={DynamicFeedIcon} text={state.student_floor} label="floor" />
                </>
            )}
            {type === '4' && state.user && (
                <>
                    <RenderRow Icon={CalendarMonthIcon} text={state.academic_year_name} label="academic_name" />
                    <RenderRow Icon={ClassIcon} text={state.class_name} label="class_name" />
                    <RenderRow Icon={ClassIcon} text={state.section_full_name} label="section" />
                    <RenderRow Icon={FingerprintIcon} text={state.student_sis_id} label="st_sis_id" />
                </>
            )}
            {type === '5' && state.user && (
                <>
                    <RenderRow Icon={MyLocationIcon} text={state.branch_name} label="branch_name" />
                    <RenderRow Icon={AccountBoxIcon} text={state.user.username} label="username" />
                    <RenderRow Icon={KeyIcon} text={state.user.default_password} label="default_password" />
                </>
            )}
        </div>
    );
};

SummaryList.propTypes = {
    row: PropTypes.object.isRequired,
    type: PropTypes.string
};

export default SummaryList;
