import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { FormControl, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export default function DatePickerCust({ value, onChange, style, local, type, ...rest }) {
    const [state, setState] = useState('');
    const [openTo, setOpenTo] = useState('day');
    const [inputStyle, setInputStyle] = useState({});

    const [classStyle, setClassStyle] = useState('');
    const [locale, setLocale] = useState('en-US');

    useEffect(() => {
        setOpenTo(type || 'day');
    }, [type]);

    useEffect(() => {
        setLocale(local || 'en-US');
    }, [local]);

    useEffect(() => {
        setState(value || null);
    }, [value]);

    useEffect(() => {
        // default style
        let TempStyle = {
            fontSize: '14px',
            marginRight: '5px'
        };
        // this is to check the style in case of custom styles
        if (style) {
            TempStyle = { ...TempStyle, ...style };
        }
        setInputStyle(TempStyle);
    }, [style]);

    const localeMap = {
        en: enLocale,
        fr: frLocale
    };

    const maskMap = {
        fr: '__/__/____',
        en: '__/__/____'
    };

    useEffect(() => {
        // setClassStyle(!state && rest.required ? 'error' : '');
    }, [state, rest.required]);

    useEffect(() => {
        setClassStyle(' ');
    }, []);

    return (
        <div style={inputStyle} className={`DatePicker ${classStyle}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                <FormControl
                    className="datePickerCust"
                    fullWidth
                    required={rest.required}
                    aria-invalid={!state && rest.required ? 'true' : 'false'}
                    name={rest.name}
                >
                    <DatePicker
                        size="small"
                        allowKeyboardControl
                        className={classStyle}
                        // mask={maskMap[locale]}
                        label={
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <FormattedMessage id={rest.id} />
                                </div>
                                <div style={{ marginLeft: '5px' }}>{rest.required ? ' *' : ''}</div>
                            </div>
                        }
                        name={rest.name}
                        required={rest.required}
                        openTo={openTo}
                        value={state}
                        onChange={(val) => {
                            const e = {
                                target: { value: val, name: rest.name }
                            };
                            setState(e.target.value, rest.required);
                            if (e.target.value === null && rest.required) {
                                setClassStyle('error');
                            } else {
                                if (onChange) onChange(e);
                                setClassStyle(' ');
                                const node = document.getElementsByName(rest.name);
                                node[0].children[0].classList.remove('error');
                            }
                        }}
                        style={inputStyle}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        {...rest}
                    />
                    <FormHelperText className={` ${classStyle}`} style={{ marginLeft: '14px' }}>
                        {classStyle === 'error' ? <FormattedMessage id="field_is_required" /> : rest.text?.rest.text}
                    </FormHelperText>
                </FormControl>
            </LocalizationProvider>
        </div>
    );
}
