import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const TableCellEdit = ({ disableEdit, row, handleClickOpenDialog, setSelectedRow }) => {
    const theme = useTheme();
    return (
        <TableCell align="center" sx={{ p: 0 }} style={{ textAlign: 'center' }} size="small">
            {!disableEdit &&
                (!row.disable_edit ? (
                    <Tooltip title="Edit">
                        <IconButton
                            style={{ color: theme.palette.primary.main }}
                            size="small"
                            disabled={row.disable_edit}
                            onClick={() => {
                                handleClickOpenDialog('edit');
                                setSelectedRow(row);
                            }}
                        >
                            <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <IconButton
                        style={{ color: theme.palette.primary.light }}
                        size="small"
                        disabled={row.disable_edit}
                        onClick={() => {
                            handleClickOpenDialog('edit');
                            setSelectedRow(row);
                        }}
                    >
                        <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                    </IconButton>
                ))}
        </TableCell>
    );
};

TableCellEdit.propTypes = {
    row: PropTypes.object.isRequired,
    handleClickOpenDialog: PropTypes.func.isRequired,
    setSelectedRow: PropTypes.func.isRequired,
    disableEdit: PropTypes.bool
};

export default TableCellEdit;
