import moment from 'moment';
// table sort
const stringToDate = (dateString) => {
    const [day, month, year] = dateString.split('.');
    return new Date([month, day, year].join('.'));
};

export const checkDate = (date1, date2) => {
    if (date1 && date2) {
        const d1 = moment(stringToDate(date1), 'YYYY-MM-DD').toDate();
        const d2 = moment(stringToDate(date2), 'YYYY-MM-DD').toDate();

        const dd1 = moment(d1).format('YYYY-MM-DD');
        const dd2 = moment(d2).format('YYYY-MM-DD');

        const diff = moment(dd1).diff(moment(dd2), 'days');
        console.log(date1, d1, dd1);
        if (diff > 0) {
            return 1;
        }

        if (diff < 0) {
            return -1;
        }
        if (diff === 0) {
            return 0;
        }
    }
    return 0;
};
// eslint-disable-next-line import/prefer-default-export
export const descendingComparator = (a, b, orderBy, orderByType) => {
    if (orderByType === 'date') {
        if (checkDate(b[orderBy], a[orderBy]) < 0) return -1;
        if (checkDate(b[orderBy], a[orderBy]) > 0) return 1;
        return 0;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy, orderByType) =>
    order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, orderByType)
        : (a, b) => -descendingComparator(a, b, orderBy, orderByType);

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        // console.log(a[0], b[0]);
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};
