/* eslint-disable prettier/prettier */
import { Fab, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MotherInfForm from './MotherInfForm';
import AddIcon from '@mui/icons-material/AddTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import './Parent.scss';
import Stepperaction from 'ui-component/StepperWizard/StepperAction';
// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

const MotherInformation = ({ state, setState, handleNext, handleBack, setErrorIndex, nationality }) => {
    const theme = useTheme();
    const [selectedRow, setSelectedRow] = useState([]);

    const addForm = () => {
        const newVal = [...state];
        let tempRow = 0;
        newVal.forEach((el) => {
            tempRow = el.row > tempRow ? el.row : tempRow;
        });
        newVal.push({
            row: tempRow + 1,
            country_id: null,
            id: null,
            parent_id: null,
            wife_alive: null,
            wife_email: null,
            wife_first_name: null,
            wife_full_name: null,
            wife_last_name: null,
            wife_middle_name: null,
            wife_mobile_number: null,
            wife_telephone_number: null
        });
        setState(newVal);
    };
    const deleteForm = () => {
        const newVal = [...state];
        // eslint-disable-next-line no-restricted-globals
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure to delete selected record ?')) {
            const filteredData = newVal.filter((el) => el.row !== selectedRow);
            setState(filteredData);
        }
    };

    return (
        <div>
            <div className="toolBar">
                <Fab
                    style={{ background: theme.palette.primary.main, color: theme.palette.primary.light }}
                    size="small"
                    onClick={addForm}
                    sx={{ boxShadow: 'none', width: 32, height: 32, minHeight: 32 }}
                >
                    <AddIcon fontSize="small" />
                </Fab>
                <Fab
                    style={{ background: '#fff', color: state.length === 1 ? theme.palette.error.light : theme.palette.error.main }}
                    size="medium"
                    onClick={deleteForm}
                    sx={{ boxShadow: 'none', width: 32, height: 32, minHeight: 32 }}
                    disabled={state.length === 1}
                >
                    <DeleteIcon fontSize="medium" />
                </Fab>
            </div>
            <div className="motherBody">
                {state &&
                    state.map((elem, index) => (
                        <MotherInfForm
                            key={index}
                            idx={index}
                            mainData={state}
                            state={elem}
                            setState={setState}
                            handleNext={handleNext}
                            nationality={nationality}
                            handleBack={handleBack}
                            setErrorIndex={setErrorIndex}
                            setSelectedRow={setSelectedRow}
                            selectedRow={selectedRow}
                        />
                    ))}
            </div>
        </div>
    );
};

MotherInformation.propTypes = {
    state: PropTypes.array,
    setState: PropTypes.func,
    handleNext: PropTypes.func,
    setErrorIndex: PropTypes.func,
    handleBack: PropTypes.func,
    nationality: PropTypes.array
};

export default MotherInformation;
