import { FormattedMessage } from 'react-intl';

export const gender = [
    { id: 'Male', name: 'Male' },
    { id: 'Female', name: 'Female' }
];

export const bloodTypes = [
    { id: 'A+', name: 'A+' },
    { id: 'A-', name: 'A-' },
    { id: 'B+', name: 'B+' },
    { id: 'B-', name: 'B-' },
    { id: 'O+', name: 'O+' },
    { id: 'O-', name: 'O-' },
    { id: 'AB+', name: 'AB+' },
    { id: 'AB-', name: 'AB-' }
];

export const governorates = [
    {
        id: 'Beirut',
        label: <FormattedMessage id="Beirut" />
    },
    {
        id: 'Beqaa',
        label: <FormattedMessage id="Beqaa" />
    },
    {
        id: 'Baalbek-El-Hermel',
        label: <FormattedMessage id="Baalbek-El-Hermel" />
    },
    {
        id: 'Nabatiyeh',
        label: <FormattedMessage id="Nabatiyeh" />
    },
    {
        id: 'North-Lebanon',
        label: <FormattedMessage id="North-Lebanon" />
    },
    {
        id: 'Akkar',
        label: <FormattedMessage id="Akkar" />
    },
    {
        id: 'Mount-Lebanon',
        label: <FormattedMessage id="Mount-Lebanon" />
    },
    {
        id: 'South-Lebanon',
        label: <FormattedMessage id="South-Lebanon" />
    }
];

export const alive = [
    { data: 1, display: 'No' },
    { data: 2, display: 'Yes' }
];

export const sections = [
    { data: 'A', display: <FormattedMessage id="A" /> },
    { data: 'B', display: <FormattedMessage id="B" /> },
    { data: 'C', display: <FormattedMessage id="C" /> },
    { data: 'D', display: <FormattedMessage id="D" /> },
    { data: 'E', display: <FormattedMessage id="E" /> },
    { data: 'F', display: <FormattedMessage id="F" /> },
    { data: 'G', display: <FormattedMessage id="G" /> },
    { data: 'H', display: <FormattedMessage id="H" /> },
    { data: 'I', display: <FormattedMessage id="I" /> },
    { data: 'G', display: <FormattedMessage id="G" /> },
    { data: 'K', display: <FormattedMessage id="K" /> },
    { data: 'L', display: <FormattedMessage id="L" /> }
];

export const languages = [
    { data: 'English', display: <FormattedMessage id="English" /> },
    { data: 'French', display: <FormattedMessage id="French" /> }
];
