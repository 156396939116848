/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SelectAutoCompleteAsync from 'ui-component/GenericComponents/SelectAutoCompleteAsync';
import SelectLabel from 'ui-component/GenericComponents/SelectLabel';
import TextFieldCust from 'ui-component/GenericComponents/TextFieldCust';
import DatePickerCust from 'ui-component/GenericComponents/DatePickerCust';
import Stepperaction from 'ui-component/StepperWizard/StepperAction';

// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

const StudentInfo = ({ state, setState, handleNext, setErrorIndex, parents, genderOpt, nationality, bloodTypes, ...props }) => {
    const [wifes, setWifes] = useState([]);
    const [initValues, setInitValues] = useState({});
    const initFormikValues = () => {
        setInitValues({
            student_first_name: state.student_first_name,
            parent_id: state.parent_id,
            wife_id: state.wife_id,
            student_gender: state.student_gender,
            country_id: state.country_id,
            student_birth_date: state.student_birth_date,
            student_blood_type: state.student_blood_type,
            father_alive: state.father_alive,
            wife_alive: state.wife_alive,
            wife_mobile_number: state.wife_mobile_number
        });
    };
    useEffect(() => {
        if (state !== undefined && state.id > 0) {
            initFormikValues();
        }
    }, [state]);

    useEffect(() => {
        initFormikValues();
    }, []);

    const getParentWifes = (e) => {
        console.log(e);
        setWifes(e.wifes);
    };

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        const wifesTemp = parents.filter((el) => {
            if (el.id === state.parent_id) return el.wifes;
        });

        setWifes(wifesTemp);
        console.log('wifesTemp', wifesTemp, state.wife_id, wifesTemp[state.wife_id]);
    }, [parents, state.parent_id, state.wife_id]);

    const widthField = '250px';

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={(values) => {
                setState({
                    student_first_name: values.student_first_name,
                    parent_id: values.parent_id,
                    wife_id: values.wife_id,
                    student_gender: values.student_gender,
                    country_id: values.country_id,
                    student_birth_date: values.student_birth_date,
                    student_blood_type: values.student_blood_type,
                    father_alive: values.father_alive,
                    wife_alive: values.wife_alive,
                    wife_mobile_number: values.wife_mobile_number
                });

                handleNext();
            }}
        >
            {({ values, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row" style={{ justifyContent: 'initial' }}>
                        <Field
                            required
                            id="student_first_name"
                            name="student_first_name"
                            value={values.student_first_name}
                            style={{ width: widthField, marginRight: '5px' }}
                            as={TextFieldCust}
                        />
                        <Field
                            required
                            id="parent_id"
                            name="parent_id"
                            value={values.parent_id}
                            acWidth={widthField}
                            option={parents}
                            data="id"
                            display="parent_full_name"
                            as={SelectAutoCompleteAsync}
                            onChange={(e) => {
                                getParentWifes(e);
                                handleChange(e);
                            }}
                        />
                        <Field
                            required
                            id="wife_id"
                            name="wife_id"
                            value={values.wife_id}
                            acWidth={widthField}
                            option={wifes}
                            data="id"
                            display="wife_full_name"
                            as={SelectAutoCompleteAsync}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div className="row" style={{ justifyContent: 'initial' }}>
                        <Field
                            required
                            id="student_gender"
                            name="student_gender"
                            value={values.student_gender}
                            style={{ width: widthField }}
                            option={genderOpt}
                            data="id"
                            display="name"
                            as={SelectLabel}
                        />
                        <Field
                            required
                            id="student_birth_date"
                            name="student_birth_date"
                            value={values.student_birth_date}
                            style={{ width: widthField }}
                            as={DatePickerCust}
                        />
                        <Field
                            required
                            id="country_id"
                            name="country_id"
                            value={values.country_id}
                            style={{ width: widthField }}
                            option={nationality}
                            data="id"
                            display="country_en_name"
                            as={SelectLabel}
                        />
                    </div>
                    <div className="row" style={{ justifyContent: 'initial' }}>
                        <Field
                            required
                            id="student_blood_type"
                            name="student_blood_type"
                            value={values.student_blood_type}
                            style={{ width: widthField }}
                            option={bloodTypes}
                            data="id"
                            display="name"
                            as={SelectLabel}
                        />
                        <Field
                            id="father_alive"
                            name="father_alive"
                            value={values.father_alive}
                            style={{ width: widthField }}
                            option={[
                                { data: 1, display: 'No' },
                                { data: 2, display: 'Yes' }
                            ]}
                            disabled
                            as={SelectLabel}
                        />
                        <Field
                            id="wife_alive"
                            name="wife_alive"
                            value={values.wife_alive}
                            style={{ width: widthField }}
                            option={[
                                { data: 1, display: 'No' },
                                { data: 2, display: 'Yes' }
                            ]}
                            disabled
                            as={SelectLabel}
                        />
                    </div>
                    <div className="row" style={{ justifyContent: 'initial' }}>
                        <Field
                            id="parent_marital_status"
                            name="parent_marital_status"
                            value={values.parent_marital_status}
                            style={{ width: widthField }}
                            option={[
                                { data: 1, display: 'Married' },
                                { data: 2, display: 'Divorced' }
                            ]}
                            disabled
                            as={SelectLabel}
                        />
                        <Field
                            id="wife_mobile_number"
                            name="wife_mobile_number"
                            value={values.wife_mobile_number}
                            style={{ width: widthField }}
                            disabled
                            as={TextFieldCust}
                        />
                    </div>

                    <Stepperaction
                        setErrorIndex={() => {
                            setErrorIndex(0);
                        }}
                    />
                </form>
            )}
        </Formik>
    );
};

StudentInfo.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    handleNext: PropTypes.func,
    setErrorIndex: PropTypes.func
};

export default StudentInfo;
