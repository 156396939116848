// assets
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RenderCheckAll from './RenderCheckAll';

// ==============================|| TABLE HEADER ||============================== //
const TableHeaders = ({ columns, order, orderBy, onRequestSort, readOnly, disableAdd, disableRemove, disableEdit, dispNone }) => {
    const createSortHandler = (property, type) => (event) => {
        onRequestSort(event, property, type);
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell
                        size="small"
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            display: headCell.hidden ? 'none' : '',
                            width: headCell.width ? headCell.width : `${100 / columns.length}%`
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            {typeof headCell.checkAll !== 'undefined' && (
                                <RenderCheckAll
                                    hideLabel
                                    id={`${headCell.id}CheckAll`}
                                    value={0}
                                    onChange={(e) => {
                                        headCell.checkAll(e);
                                    }}
                                    style={{ marginRight: '3px', marginLeft: '0px important' }}
                                />
                            )}
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id, headCell.type)}
                                // style={{ width: headCell.width ? headCell.width : `${100 / columns.length}%` }}
                            >
                                <FormattedMessage id={headCell.label ? headCell.label : headCell.id} />
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </div>
                    </TableCell>
                ))}
                {!readOnly && dispNone !== 'none' && (!disableRemove || !disableEdit || !disableAdd) ? (
                    <TableCell
                        sortDirection={false}
                        align="center"
                        sx={{ pr: 3 }}
                        style={{ minWidth: '4%', width: '4%', display: 'center' }}
                    >
                        <TableSortLabel style={{ minWidth: '4%', marginLeft: '13px' }}>Action</TableSortLabel>
                    </TableCell>
                ) : (
                    <TableCell
                        sortDirection={false}
                        align="center"
                        sx={{ pr: 3 }}
                        style={{ minWidth: '1%', width: '1%', display: 'center' }}
                    />
                )}
            </TableRow>
        </TableHead>
    );
};

TableHeaders.propTypes = {
    columns: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    disableRemove: PropTypes.bool,
    disableEdit: PropTypes.bool,
    dispNone: PropTypes.string,
    disableAdd: PropTypes.bool
};

export default TableHeaders;
