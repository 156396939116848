/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prettier/prettier */
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import SelectLabel from 'ui-component/GenericComponents/SelectLabel';
import TextFieldCust from 'ui-component/GenericComponents/TextFieldCust';
import Stepperaction from 'ui-component/StepperWizard/StepperAction';
import { alive } from '../../../Ddl';

// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

const MotherInfForm = ({
    state,
    setState,
    handleNext,
    mainData,
    idx,
    nationality,
    handleBack,
    setErrorIndex,
    setSelectedRow,
    selectedRow
}) => {
    const [initValues, setInitValues] = useState({});
    const initFormikValues = () => {
        setInitValues({
            wife_first_name: state.wife_first_name,
            wife_middle_name: state.wife_middle_name,
            wife_last_name: state.wife_last_name,
            country_id: state.country_id,
            wife_alive: state.wife_alive,
            wife_mobile_number: state.wife_mobile_number,
            wife_telephone_number: state.wife_telephone_number,
            wife_email: state.wife_email
        });
    };
    useMemo(() => {
        if ((state !== undefined && state.id > 0) || state.id === null) {
            initFormikValues();
        }
    }, [state]);

    useEffect(() => {
        initFormikValues();
    }, []);
    useEffect(() => {
        setSelectedRow(idx);
    }, [idx, setSelectedRow]);
    useEffect(() => {
        const myTimeout = setTimeout(document.getElementsByClassName('motherForm')[0].click(), 1000);
        // eslint-disable-next-line no-unused-expressions
        // () => {
        //     clearTimeout(myTimeout);
        // };
    }, []);

    const formClick = () => {
        [].forEach.call(document.getElementsByClassName('motherForm'), (e) => {
            // eslint-disable-next-line eqeqeq
            if (e.getAttribute('row') == idx) {
                e.classList.add('selectedRow');
            } else {
                e.classList.remove('selectedRow');
            }
        });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={(values) => {
                [].forEach.call(document.getElementsByClassName('motherNext'), (e) => {
                    e.click();
                    handleNext();
                });
                // mainData.map((obj) => [motherStep2].find((o) => o.id === obj.id) || obj);
                const tempData = mainData;
                tempData[idx] = {
                    ...state,
                    wife_first_name: values.wife_first_name,
                    wife_middle_name: values.wife_middle_name,
                    wife_last_name: values.wife_last_name,
                    country_id: values.country_id,
                    wife_alive: values.wife_alive,
                    wife_mobile_number: values.wife_mobile_number,
                    wife_telephone_number: values.wife_telephone_number,
                    wife_email: values.wife_email
                };
                setState(tempData);
            }}
        >
            {({ values, handleSubmit }) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <form
                    onSubmit={handleSubmit}
                    onClick={() => {
                        setSelectedRow(state.row);
                    }}
                >
                    <div
                        onClick={formClick}
                        className="motherForm"
                        row={idx}
                        style={{ borderBottom: '1px solid gray', marginBottom: '20px', marginTop: '20px', width: '100%' }}
                    >
                        <div className="row">
                            <Field
                                required
                                id="wife_first_name"
                                name="wife_first_name"
                                value={values.wife_first_name}
                                style={{ width: '250px', marginRight: '5px' }}
                                as={TextFieldCust}
                            />

                            <Field
                                required
                                id="wife_middle_name"
                                name="wife_middle_name"
                                value={values.wife_middle_name}
                                style={{ width: '250px' }}
                                as={TextFieldCust}
                            />

                            <Field
                                required
                                id="wife_last_name"
                                name="wife_last_name"
                                value={values.wife_last_name}
                                style={{ width: '250px' }}
                                as={TextFieldCust}
                            />
                        </div>
                        <div className="row" style={{ justifyContent: 'initial' }}>
                            <Field
                                required
                                id="country_id"
                                name="country_id"
                                value={values.country_id}
                                style={{ width: '250px' }}
                                option={nationality}
                                data="id"
                                display="country_en_name"
                                as={SelectLabel}
                            />

                            <Field
                                required
                                id="wife_alive"
                                name="wife_alive"
                                value={values.wife_alive}
                                style={{ width: '250px' }}
                                option={alive}
                                as={SelectLabel}
                            />
                        </div>
                        <div className="row" style={{ justifyContent: 'initial' }}>
                            <Field
                                required
                                id="wife_mobile_number"
                                name="wife_mobile_number"
                                value={values.wife_mobile_number}
                                style={{ width: '250px', marginRight: '5px' }}
                                as={TextFieldCust}
                            />

                            <Field
                                required
                                id="wife_telephone_number"
                                name="wife_telephone_number"
                                value={values.wife_telephone_number}
                                style={{ width: '250px' }}
                                as={TextFieldCust}
                            />
                        </div>
                        <div className="row">
                            <Field
                                required
                                id="wife_email"
                                name="wife_email"
                                value={values.wife_email}
                                style={{ width: '250px', marginRight: '5px' }}
                                as={TextFieldCust}
                            />
                        </div>
                    </div>
                    <div className={idx === mainData.length - 1 ? '' : 'hidden'}>
                        <Stepperaction
                            handleBack={handleBack}
                            setErrorIndex={() => {
                                setErrorIndex(1);
                            }}
                            backClass="motherBack"
                            nextClass="motherNext"
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
};

MotherInfForm.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    handleNext: PropTypes.func,
    mainData: PropTypes.array,
    nationality: PropTypes.array
};

export default MotherInfForm;
