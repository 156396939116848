/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SelectLabel from 'ui-component/GenericComponents/SelectLabel';
import TextFieldCust from 'ui-component/GenericComponents/TextFieldCust';
import Stepperaction from 'ui-component/StepperWizard/StepperAction';

// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

const ContactInfo = ({ state, setState, handleNext, handleBack, setErrorIndex }) => {
    const [initValues, setInitValues] = useState({});
    const initFormikValues = () => {
        setInitValues({
            parent_responsibility: state.parent_responsibility,
            student_mobile_number: state.student_mobile_number,
            parent_telephone_number: state.parent_telephone_number,
            parent_mobile_number: state.parent_mobile_number,
            wife_mobile_number: state.wife_mobile_number
        });
    };
    useEffect(() => {
        if (state !== undefined && state.id > 0) {
            initFormikValues();
        }
    }, [state]);

    useEffect(() => {
        initFormikValues();
    }, []);

    const widthField = '250px';

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={(values) => {
                setState({
                    parent_responsibility: values.parent_responsibility,
                    student_mobile_number: values.student_mobile_number,
                    parent_telephone_number: values.parent_telephone_number,
                    parent_mobile_number: values.parent_mobile_number,
                    wife_mobile_number: values.wife_mobile_number
                });
                handleNext();
            }}
        >
            {({ values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row" style={{ justifyContent: 'initial' }}>
                        <Field
                            id="parent_responsibility"
                            name="parent_responsibility"
                            value={values.parent_responsibility}
                            style={{ width: widthField }}
                            option={[
                                { data: 'Father', display: 'Father' },
                                { data: 'Mother', display: 'Mother' }
                            ]}
                            as={SelectLabel}
                        />
                        <Field
                            required
                            id="student_mobile_number"
                            name="student_mobile_number"
                            value={values.student_mobile_number}
                            style={{ width: widthField, marginRight: '5px' }}
                            as={TextFieldCust}
                        />
                    </div>
                    <div className="row" style={{ justifyContent: 'initial' }}>
                        <Field
                            disabled
                            id="parent_telephone_number"
                            name="parent_telephone_number"
                            value={values.parent_telephone_number}
                            style={{ width: widthField, marginRight: '5px' }}
                            as={TextFieldCust}
                        />
                        <Field
                            disabled
                            id="parent_mobile_number"
                            name="parent_mobile_number"
                            value={values.parent_mobile_number}
                            style={{ width: widthField, marginRight: '5px' }}
                            as={TextFieldCust}
                        />
                        <Field
                            disabled
                            id="wife_mobile_number"
                            name="wife_mobile_number"
                            value={values.wife_mobile_number}
                            style={{ width: widthField, marginRight: '5px' }}
                            as={TextFieldCust}
                        />
                    </div>

                    <Stepperaction
                        handleBack={handleBack}
                        setErrorIndex={() => {
                            setErrorIndex(1);
                        }}
                    />
                </form>
            )}
        </Formik>
    );
};

ContactInfo.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    handleNext: PropTypes.func,
    setErrorIndex: PropTypes.func,
    handleBack: PropTypes.func
};

export default ContactInfo;
