/* eslint-disable prettier/prettier */
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SelectAutoCompleteAsync from 'ui-component/GenericComponents/SelectAutoCompleteAsync';
import SelectLabel from 'ui-component/GenericComponents/SelectLabel';
import TextFieldCust from 'ui-component/GenericComponents/TextFieldCust';
import Stepperaction from 'ui-component/StepperWizard/StepperAction';

// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

const UserInfo = ({ state, setState, handleNext, handleBack, setErrorIndex }) => {
    const [initValues, setInitValues] = useState({});
    const initFormikValues = () => {
        setInitValues({
            username: state.username,
            default_password: state.default_password
        });
    };
    useEffect(() => {
        if (state !== undefined && state.id > 0) {
            initFormikValues();
        }
    }, [state]);

    useEffect(() => {
        initFormikValues();
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={(values) => {
                const newState = {
                    username: values.username,
                    default_password: values.default_password
                };
                setState(newState);
                handleNext(newState);
            }}
        >
            {({ values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row" style={{ justifyContent: 'initial' }}>
                        <Field
                            id="username"
                            name="username"
                            value={values.username}
                            style={{ width: '250px' }}
                            disabled
                            as={TextFieldCust}
                        />

                        <Field
                            id="default_password"
                            name="default_password"
                            value={values.default_password}
                            style={{ width: '250px' }}
                            disabled
                            as={TextFieldCust}
                        />
                    </div>
                    <Stepperaction
                        lastStep
                        handleBack={handleBack}
                        setErrorIndex={() => {
                            setErrorIndex(3);
                        }}
                    />
                </form>
            )}
        </Formik>
    );
};

UserInfo.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    handleNext: PropTypes.func,
    setErrorIndex: PropTypes.func,
    handleBack: PropTypes.func
};

export default UserInfo;
