// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconList } from '@tabler/icons';

// constant
const icons = {
    IconList
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const definitions = {
    id: 'definitions',
    title: <FormattedMessage id="definitions" />,
    type: 'group',
    children: [
        {
            id: 'general_definitions',
            title: <FormattedMessage id="general-definitions" />,
            type: 'collapse',
            icon: icons.IconList,
            children: [
                {
                    id: 'buildings',
                    title: <FormattedMessage id="buildings-page" />,
                    type: 'item',
                    url: '/buildings',
                    breadcrumbs: true
                },
                {
                    id: 'branches-page',
                    title: <FormattedMessage id="branches-page" />,
                    type: 'item',
                    url: '/branches',
                    breadcrumbs: true
                },
                {
                    id: 'configrations-page',
                    title: <FormattedMessage id="configrations-page" />,
                    type: 'item',
                    url: '/configrations',
                    breadcrumbs: true
                },

                {
                    id: 'rooms-page',
                    title: <FormattedMessage id="rooms-page" />,
                    type: 'item',
                    url: '/rooms',
                    breadcrumbs: true
                },
                {
                    id: 'menu-page',
                    title: <FormattedMessage id="menu-page" />,
                    type: 'item',
                    url: '/pages',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'academic_definitions',
            title: <FormattedMessage id="academic-definitions" />,
            type: 'collapse',
            icon: icons.IconList,
            children: [
                {
                    id: 'academic-years',
                    title: <FormattedMessage id="academic-year-page" />,
                    type: 'item',
                    url: '/academic-years',
                    breadcrumbs: true
                },
                {
                    id: 'cycles',
                    title: <FormattedMessage id="cycles-page" />,
                    type: 'item',
                    url: '/cycles',
                    breadcrumbs: true
                },
                {
                    id: 'classes',
                    title: <FormattedMessage id="classes-page" />,
                    type: 'item',
                    url: '/classes',
                    breadcrumbs: true
                },
                {
                    id: 'subjects',
                    title: <FormattedMessage id="subjects-page" />,
                    type: 'item',
                    url: '/subjects',
                    breadcrumbs: true
                },
                {
                    id: 'sectionsclasses',
                    title: <FormattedMessage id="assign-sections-classes-page" />,
                    type: 'item',
                    url: '/sectionsclasses',
                    breadcrumbs: true
                },
                {
                    id: 'classesTeachers',
                    title: <FormattedMessage id="assign-classes-teachers-page" />,
                    type: 'item',
                    url: '/classesTeachers',
                    breadcrumbs: true
                },
                {
                    id: 'TeachersSubjects',
                    title: <FormattedMessage id="assign-teachers-subjects-page" />,
                    type: 'item',
                    url: '/teachersSubjects',
                    breadcrumbs: true
                },
                {
                    id: 'coordinatorsSubjects',
                    title: <FormattedMessage id="assign-coordinators-subjects-page" />,
                    type: 'item',
                    url: '/coordinatorsSubjects',
                    breadcrumbs: true
                },
                {
                    id: 'assignSupervisors',
                    title: <FormattedMessage id="assign-supervisors-page" />,
                    type: 'item',
                    url: '/assignSupervisors',
                    breadcrumbs: true
                },
                {
                    id: 'assignCycleDirectors',
                    title: <FormattedMessage id="assign-cycle-directors-page" />,
                    type: 'item',
                    url: '/assignCycleDirectors',
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default definitions;
