import { UPDATE_MESSAGES } from 'store/actions';
// eslint-disable-next-line import/no-cycle
import { Get } from 'helper/axiosHelper';

// eslint-disable-next-line import/prefer-default-export
export const getTranslationJson = (lang, dispatch) => {
    // eslint-disable-next-line no-nested-ternary
    const language = lang === 'en' ? 1 : lang === 'ar' ? 2 : 3;
    Get(`translations/getJsonTranslation?lang=${language}`)
        .then((res) => {
            if (res.data) {
                dispatch({
                    type: UPDATE_MESSAGES,
                    messages: res.data[0]
                });
            }
        })
        .catch((err) => {
            console.log('getTranslationJson', err);
        });
};

export function saveJsonObjToFile() {}
