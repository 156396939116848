/* eslint-disable no-alert */
import axios from 'axios';
import config from 'config';
import LoaderCust from 'ui-component/extended/LoaderCust';

const AxiosInstance = axios.create({
    baseURL: config.API_URL,
    // timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        api_token: window.localStorage.getItem('serviceToken')
    }
});

// eslint-disable-next-line consistent-return
AxiosInstance.interceptors.request.use(async (config) => {
    if (!config.noLoading) {
        LoaderCust.show();
    }

    try {
        const serviceToken = await localStorage.getItem('serviceToken');
        if (serviceToken) {
            config.headers.api_token = serviceToken;
            AxiosInstance.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        } else {
            localStorage.removeItem('serviceToken');
            delete AxiosInstance.defaults.headers.common.Authorization;
            config.headers.api_token = null;
            config.headers.Authorization = null;
        }
        return config;
    } catch (error) {
        alert('Error retrieving data');
        // Error retrieving data
    }
});
AxiosInstance.interceptors.response.use(
    (response) => {
        if (!response.config.noLoading) {
            LoaderCust.hide();
        }
        return response;
    },
    (error) => {
        LoaderCust.hide();
        if (error.response) {
            if (typeof error.response.data === 'object') {
                if (error.response.status === 500) {
                    console.log('error.response.status');
                    // localStorage.removeItem('serviceToken');
                    // delete AxiosInstance.defaults.headers.common.Authorization;
                    // window.location.href = '/';
                }
                return Promise.reject(error.response.data);
            }
        }
        return Promise.reject(error);
    }
);

export const Get = (api, headers) => AxiosInstance.get(api, headers);

export const Post = (api, body) => AxiosInstance.post(api, body);

export const Put = (api, body) => AxiosInstance.put(api, body);

export const Del = (api) => AxiosInstance.delete(api);

export const GetDdl = async (api) => {
    let res = localStorage.getItem(`${api}`);
    if (res) {
        res = { data: JSON.parse(res) };
    } else {
        res = await AxiosInstance.get(api, { noLoading: true }).then(async (res) => {
            console.log('getddl', res);
            localStorage.setItem(`${api}`, JSON.stringify(res.data.data));
            return res.data.data;
        });
    }
    return new Promise((resolve) => {
        resolve(res);
    });
};

export default AxiosInstance;
