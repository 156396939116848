import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import { Get } from 'helper/axiosHelper';
import { dynamicSort } from './GenericFunctions';

export default function SelectLabel({ value, onChange, option, hideLabel = false, style, ...rest }) {
    const [state, setState] = useState('');
    const [stateOpt, setStateOpt] = useState([]);
    const [inputStyle, setInputStyle] = useState({});
    const [classStyle, setClassStyle] = useState('');

    useEffect(() => {
        setState(value || '');
    }, [value]);

    useEffect(() => {
        const dataField = rest.data ? rest.data : 'data';
        const displayField = rest.display ? rest.display : 'display';
        if (!rest.removenull) {
            // eslint-disable-next-line react/prop-types
            if (option && !option.some((value) => value[dataField] === null)) {
                const dummy = {};
                dummy[dataField] = null;
                dummy[displayField] = <em>None</em>;
                // eslint-disable-next-line react/prop-types
                option.unshift(dummy);
            }
        }
        if (stateOpt) {
            setStateOpt([]);
        }
        setStateOpt(option);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option, rest.data, rest.display, rest.removenull]);

    useEffect(() => {
        // default style
        let TempStyle = {
            fontSize: '14px',
            marginRight: '5px'
        };

        // this is to check the style in case of custom styles
        if (style) {
            TempStyle = { ...TempStyle, ...style };
        }
        setInputStyle(TempStyle);
    }, [style]);

    useEffect(() => {
        setClassStyle(!state && rest.required ? 'error' : '');
    }, [state, rest.required]);

    useEffect(() => {
        setClassStyle('');
    }, []);

    useEffect(() => {
        const refreshDdl = () => {
            Get(rest.dddw).then((result) => {
                const res = result.data;
                const dataField = rest.data ? rest.data : 'data';
                const displayField = rest.display ? rest.display : 'display';

                if (!rest.removenull) {
                    const dummy = {};
                    dummy[dataField] = null;
                    dummy[displayField] = <em>None</em>;
                    res.data.unshift(dummy);
                }
                if (stateOpt) {
                    setStateOpt([]);
                }
                if (rest.sort) {
                    res.data.sort(dynamicSort(rest.sort));
                } else {
                    res.data.sort(dynamicSort(displayField));
                }
                setStateOpt(res.data);
            });
        };
        if (rest.dddw) {
            refreshDdl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.data, rest.dddw, rest.display, rest.removenull, rest.sort]);

    return (
        <div className={`selectCust ${classStyle}`}>
            <FormControl
                className={`${classStyle}`}
                fullWidth
                required={rest.required}
                aria-invalid={!state && rest.required ? 'true' : 'false'}
            >
                {!hideLabel && (
                    <InputLabel style={{ top: '-5px' }}>
                        <FormattedMessage id={rest.id} />
                    </InputLabel>
                )}
                <Select
                    size="small"
                    className={`SeclectLabel ${classStyle}`}
                    name={rest.name}
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value);
                        if (!e.target.value && rest.required) {
                            setClassStyle('error');
                        } else {
                            if (onChange) onChange(e);
                            setClassStyle(' ');
                        }
                    }}
                    inputRef={rest.ref}
                    {...rest}
                    style={inputStyle}
                    aria-invalid={!state && rest.required ? 'true' : 'false'}
                >
                    {stateOpt
                        ? stateOpt.map((item) => (
                              <MenuItem key={rest.data ? item[rest.data] : item.data} value={rest.data ? item[rest.data] : item.data}>
                                  {rest.display ? item[rest.display] : item.display}
                              </MenuItem>
                          ))
                        : rest.children}
                </Select>
                <FormHelperText className={` ${classStyle}`}>
                    {classStyle === 'error' ? <FormattedMessage id="field_is_required" /> : rest.text?.rest.text}
                </FormHelperText>
            </FormControl>
        </div>
    );
}
