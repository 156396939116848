import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import amiriFont from './PdfFonts';

// eslint-disable-next-line import/prefer-default-export
const exportToExcel = async (intl, dataToExport, columns, type, title) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const columnLabels = [];
    const columnNames = [];
    await columns.forEach((col) => {
        if (!col.hidden) {
            columnLabels.push(intl.messages[col.id]);
            columnNames.push(col.id);
        }
    });

    const newData = dataToExport.map((row) => {
        Object.keys(row).forEach((elem) => {
            if (!columnNames.includes(elem)) {
                delete row[elem];
            }
        });
        return row;
    });

    const workSheet = XLSX.utils.json_to_sheet(newData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(workSheet, [columnLabels]);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet#1');
    // Buffer
    // let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
    // export as xlsx
    if (type === 1) {
        // Binary string
        XLSX.write(workBook, { bookType: 'xls', type: 'binary' });
        // Download
        XLSX.writeFile(workBook, `${title}.xls`);
    }
    // export as xlsx
    else if (type === 2) {
        // Binary string
        XLSX.write(workBook, { bookType: 'csv', type: 'binary' });
        // Download
        XLSX.writeFile(workBook, `${title}.csv`);
    }
};

const exportToPdf = async (intl, dataToPdf, columns, title) => {
    const headersCol = [];
    const columnNames = [];
    await columns.forEach((col) => {
        if (!col.hidden) {
            headersCol.push(intl.messages[col.id]);
            columnNames.push(col.id);
        }
    });

    const newData = dataToPdf.map((row) => {
        Object.keys(row).forEach((elem) => {
            if (!columnNames.includes(elem)) {
                delete row[elem];
            }
        });
        return row;
    });

    // Add new font for arabic

    // eslint-disable-next-line func-names
    const callAddFont = function () {
        this.addFileToVFS('Amiri-Regular-normal.ttf', amiriFont);
        this.addFont('Amiri-Regular-normal.ttf', 'Amiri-Regular', 'normal');
    };
    jsPDF.API.events.push(['addFonts', callAddFont]);

    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    // eslint-disable-next-line new-cap
    const doc = new jsPDF(orientation, unit, size);

    doc.setFont('Amiri-Regular', 'normal');
    doc.setFontSize(15);
    // console.log(doc.getFontList());
    const headers = [headersCol];
    const dataDis = newData.map((elt) => Object.values(elt));

    doc.text(title, 40, 20);

    doc.autoTable({
        head: headers,
        body: dataDis,
        // startY: 50,
        rowPageBreak: 'auto',
        bodyStyles: {
            font: 'Amiri-Regular'
        },
        headStyles: {
            marginTop: 5
        }
    });

    doc.save(`${title}.pdf`);
};

export { exportToExcel, exportToPdf };
