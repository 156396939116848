import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import TableCellAdd from './TableCellAdd';
import TableCellEdit from './TableCellEdit';
import TableCellRemove from './TableCellRemove';
import TableCellWin from './TableCellWin';

const TableCellActions = ({
    DialogComponentAdd,
    toolTipAdd,
    disableAdd,
    disableRemove,
    disableEdit,
    row,
    handleClickOpenDialog,
    setSelectedRow,
    dialogWin
}) => (
    <TableCell align="center" sx={{ pr: 3 }} style={{ textAlign: 'center' }} size="small">
        {DialogComponentAdd && (
            <TableCellAdd
                toolTipAdd={toolTipAdd}
                disableAdd={disableAdd}
                row={row}
                handleClickOpenDialog={handleClickOpenDialog}
                setSelectedRow={setSelectedRow}
            />
        )}
        {dialogWin.length > 0 &&
            dialogWin[0].iconWin !== '' &&
            dialogWin.map((el, index) => (
                <TableCellWin
                    key={index}
                    index={index}
                    IconWin={el.iconWin}
                    toolTipWin={el.toolTipWin}
                    disabledField={el.disabledField}
                    row={row}
                    handleClickOpenDialog={handleClickOpenDialog}
                    setSelectedRow={setSelectedRow}
                />
            ))}
        <TableCellEdit disableEdit={disableEdit} row={row} handleClickOpenDialog={handleClickOpenDialog} setSelectedRow={setSelectedRow} />
        <TableCellRemove
            disableRemove={disableRemove}
            row={row}
            handleClickOpenDialog={handleClickOpenDialog}
            setSelectedRow={setSelectedRow}
        />
    </TableCell>
);

TableCellActions.propTypes = {
    row: PropTypes.object.isRequired,
    handleClickOpenDialog: PropTypes.func.isRequired,
    setSelectedRow: PropTypes.func.isRequired,
    disableRemove: PropTypes.bool,
    disableEdit: PropTypes.bool,
    disableAdd: PropTypes.bool,
    toolTipAdd: PropTypes.string,
    DialogComponentAdd: PropTypes.func,
    dialogWin: PropTypes.array
};

export default TableCellActions;
