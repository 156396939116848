import { useState, useEffect } from 'react';
import CheckboxCust from 'ui-component/GenericComponents/CheckboxCust';

const RenderCheckAll = ({ value, onChange, id, ...rest }) => {
    const [state, setState] = useState();
    useEffect(() => {
        setState(value);
    }, [value]);

    return (
        <div className="renderCheckAll">
            <CheckboxCust
                hideLabel
                id={id}
                name={id}
                value={state}
                onChange={(e) => {
                    onChange(e);
                }}
                {...rest}
            />
        </div>
    );
};

export default RenderCheckAll;
