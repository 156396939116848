/* eslint-disable prettier/prettier */
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const CheckboxCust = ({ value, hideLabel = false, onChange = () => {}, style, ...rest }) => {
    const [state, setState] = useState(false);
    const [inputStyle, setInputStyle] = useState({});
    const [classStyle, setClassStyle] = useState('');

    useEffect(() => {
        setState(value === 1);
    }, [value]);

    useEffect(() => {
        // default style
        let TempStyle = {
            fontSize: '14px'
        };
        // this is to check the style in case of custom styles
        if (style) {
            TempStyle = { ...TempStyle, ...style };
        }
        setInputStyle(TempStyle);
    }, [style]);

    useEffect(() => {
        setClassStyle(!state && rest.required ? 'error' : '');
    }, [state, rest.required]);

    useEffect(() => {
        setClassStyle('');
    }, []);

    return (
        <div className={`checkboxCust ${classStyle}`}>
            <FormControl component="fieldset">
                <FormControlLabel
                    value={<FormattedMessage id={rest.id} />}
                    control={
                        <Checkbox
                            size="small"
                            className={`checkboxCust ${classStyle}`}
                            checked={state}
                            required={rest.required}
                            onChange={(e) => {
                                if (onChange) {
                                    e.target.value = e.target.checked ? 1 : 0;
                                    onChange(e);
                                    setState(e.target.checked);
                                }
                            }}
                            inputRef={rest.ref}
                            {...rest}
                            style={inputStyle}
                        />
                    }
                    label={rest.id && !hideLabel ? <FormattedMessage id={rest.id} /> : ''}
                    labelPlacement={rest.placement ? rest.placement : 'start'}
                />
            </FormControl>
        </div>
    );
};

export default CheckboxCust;
