import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    },
    '& .css-1a61pv3-MuiPaper-root-MuiDialog-paper': {
        maxWidth: '100% !important'
    },
    '& .css-ahwpps-MuiDialogContent-root': {
        overflowY: 'initial',
        paddingBottom: '10px'
    },
    '& .css-w43q6a-MuiPaper-root-MuiDialog-paper': {
        overflowY: 'initial'
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, pt: 0, width: 1 }} {...other}>
            <Typography sx={{ width: 0.95 }}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        width: 0.05,
                        m: 0,
                        p: 0
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export default function DialogCust({
    DialogComponent,
    dialogObj = {
        winTitleAction: '',
        winTitleAdd: '',
        winTitle: '',
        saveLabel: '',
        cancelLabel: '',
        delLabel: '',
        saveColor: '',
        cancelColor: '',
        delColor: ''
    },
    showDialog,
    onClose,
    forceRefresh,
    ...rest
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [fns, setFns] = useState({
        delFn: false,
        saveFn: false,
        newFn: false,
        cancelFn: false
    });

    useEffect(() => {
        if (showDialog === true) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        setFns({
            delFn: false,
            saveFn: false,
            newFn: false,
            cancelFn: false
        });
    }, [showDialog]);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        if (forceRefresh) forceRefresh();
    };
    // eslint-disable-next-line no-undef

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={false}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {(dialogObj.winTitleAction !== undefined && dialogObj.winTitleAction ? (
                    <>
                        <FormattedMessage id={dialogObj.winTitleAction} />{' '}
                        <FormattedMessage
                            id={dialogObj.winTitleAction === 'Add' ? dialogObj.winTitleAdd || dialogObj.winTitle : dialogObj.winTitle}
                        />
                    </>
                ) : (
                    <FormattedMessage id={dialogObj.winTitle ? dialogObj.winTitle : ''} />
                )) || 'Edit row'}
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ maxWidth: 1, width: 1 }}>
                <DialogComponent fns={setFns} dialogObj={dialogObj} onClose={onClose} forceRefresh={forceRefresh} {...rest} />
            </DialogContent>
            <DialogActions>
                {fns.saveFn ? (
                    <Button
                        style={{ background: dialogObj.saveColor || theme.palette.primary.main, color: theme.palette.primary.light }}
                        autoFocus
                        onClick={async () => {
                            if (document.querySelectorAll("[aria-invalid='true']").length > 0) {
                                const nodes = document.querySelectorAll("[aria-invalid='true']");

                                nodes.forEach((node) => {
                                    if (node.classList.contains('datePickerCust')) {
                                        node.children[0].classList.add('error');
                                    } else {
                                        node.classList.add('error');
                                        if (node.classList.contains('asyncSelectForm')) {
                                            document.getElementsByClassName('css-xsouc3-control')[0].style.borderColor =
                                                theme.palette.error.main;
                                            document.getElementsByClassName(
                                                'css-xsouc3-control'
                                            )[0].style.boxShadow = `0 0 0 0px #${theme.palette.error.main}`;
                                        }
                                    }
                                });
                                dispatch({
                                    type: SNACKBAR_OPEN,
                                    open: true,
                                    message: 'Mandatory field is required',
                                    variant: 'alert',
                                    alertSeverity: 'error'
                                });
                                return;
                            }
                            await fns.saveFn();
                            handleClose();
                        }}
                    >
                        {dialogObj.saveLabel || 'Save'}
                    </Button>
                ) : (
                    ''
                )}
                {fns.cancelFn ? (
                    <Button
                        style={{ background: dialogObj.cancelColor || theme.palette.grey[700], color: theme.palette.primary.light }}
                        autoFocus
                        onClick={() => {
                            handleClose();
                            fns.cancelFn();
                        }}
                    >
                        {dialogObj.cancelLabel || 'No'}
                    </Button>
                ) : (
                    ''
                )}
                {fns.delFn ? (
                    <Button
                        style={{ background: dialogObj.delColor || theme.palette.success.main, color: theme.palette.primary.light }}
                        autoFocus
                        onClick={() => {
                            handleClose();
                            if (forceRefresh) forceRefresh();
                            fns.delFn();
                        }}
                    >
                        {dialogObj.delLabel || 'Yes'}
                    </Button>
                ) : (
                    ''
                )}
            </DialogActions>
        </BootstrapDialog>
    );
}

DialogCust.propTypes = {
    DialogComponent: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    dialogObj: PropTypes.object.isRequired,
    showDialog: PropTypes.bool.isRequired,
    forceRefresh: PropTypes.func
};
