import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { upsertApi } from 'ui-component/GenericComponents/GenericFunctions';
import StepperWizard from 'ui-component/StepperWizard';
import MotherInformation from '../parents/MotherInformation';
import AcademicInfo from './AcademicInfo';
import AddressInfo from './AddressInfo';
import ContactInfo from './ContactInfo';
import StudentInfo from './StudentInfo';
import UserInfo from './UserInfo';

const UpsertStudents = ({ row = {}, fns, forceRefresh, ...rest }) => {
    const dispatch = useDispatch();
    const [steps, setSteps] = useState([{}]);

    const [studentStep1, setStudentStep1] = useState({});
    const [contactStep2, setContactStep2] = useState({});
    const [addressStep3, setAddressStep3] = useState({});
    const [academicStep4, setAcademicStep4] = useState({});
    const [userStep5, setUserStep5] = useState({});

    useEffect(() => {
        console.log('row', row);

        setStudentStep1({
            ...row,
            father_alive: row.parent?.father_alive,
            wife_alive: row.mother?.wife_alive,
            wife_mobile_number: row.mother?.wife_mobile_number
        });
        setContactStep2({
            parent_responsibility: row.parent_responsibility,
            student_mobile_number: row.student_mobile_number,
            parent_telephone_number: row.parent?.parent_telephone_number,
            parent_mobile_number: row.parent?.parent_mobile_number,
            wife_mobile_number: row.mother?.wife_mobile_number
        });
        setAddressStep3(row);
        setAcademicStep4(row);
        setUserStep5({ ...row.user });
    }, [row]);

    const handleSubmit = (data, id) => {
        upsertApi(id, data, 'students/update', 'students/create', dispatch, forceRefresh);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const newMode = (lastStepState) => {
        row = { ...row, id: null };
        const updatedState = {
            ...row,
            ...studentStep1,
            ...addressStep3,
            ...contactStep2,
            ...academicStep4
        };

        handleSubmit(updatedState, null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateMode = (lastStepState) => {
        const updatedState = {
            ...row,
            ...studentStep1,
            ...addressStep3,
            ...contactStep2,
            ...academicStep4
        };

        handleSubmit(updatedState, row.id);
    };

    useEffect(() => {
        setSteps([
            {
                title: 'Students Information',
                component: StudentInfo,
                state: studentStep1,
                setState: setStudentStep1
            },
            {
                title: 'Contact Information',
                component: ContactInfo,
                state: contactStep2,
                setState: setContactStep2
            },
            {
                title: 'Address Information',
                component: AddressInfo,
                state: addressStep3,
                setState: setAddressStep3
            },
            {
                title: 'Academic Information',
                component: AcademicInfo,
                state: academicStep4,
                setState: setAcademicStep4
            },
            {
                title: 'User Information',
                component: UserInfo,
                state: userStep5,
                setState: setUserStep5,
                onSave: (lastStepState) => {
                    console.log('on save ', studentStep1, contactStep2, addressStep3, userStep5);
                    if (row.id !== undefined) updateMode(lastStepState);
                    else newMode(lastStepState);
                }
            }
        ]);
    }, [academicStep4, addressStep3, contactStep2, row, studentStep1, userStep5]);

    return (
        <div style={{ width: '100%' }}>
            <StepperWizard
                steps={steps}
                nationality={rest.nationality}
                parents={rest.parents}
                genderOpt={rest.genderOpt}
                bloodTypes={rest.bloodTypes}
                counties={rest.counties}
                areas={rest.areas}
                branches={rest.branches}
                academic={rest.academic}
                governorates={rest.governorates}
            />
        </div>
    );
};

UpsertStudents.propTypes = {
    row: PropTypes.object,
    fns: PropTypes.func.isRequired,
    forceRefresh: PropTypes.func
};

export default UpsertStudents;
