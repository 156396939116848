import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const TableCellRemove = ({ disableRemove, row, handleClickOpenDialog, setSelectedRow }) => {
    const theme = useTheme();
    return (
        <TableCell align="center" sx={{ p: 0 }} style={{ textAlign: 'center' }} size="small">
            {!disableRemove &&
                (!row.disable_delete ? (
                    <Tooltip title="Remove">
                        <IconButton
                            style={{ color: theme.palette.error.main }}
                            size="small"
                            disabled={row.disable_delete}
                            onClick={() => {
                                handleClickOpenDialog('delete');
                                setSelectedRow(row);
                            }}
                        >
                            <DeleteTwoTone sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <IconButton
                        style={{ color: theme.palette.error.light }}
                        size="small"
                        disabled={row.disable_delete}
                        onClick={() => {
                            handleClickOpenDialog('delete');
                            setSelectedRow(row);
                        }}
                    >
                        <DeleteTwoTone sx={{ fontSize: '1.3rem' }} />
                    </IconButton>
                ))}
        </TableCell>
    );
};

TableCellRemove.propTypes = {
    row: PropTypes.object.isRequired,
    handleClickOpenDialog: PropTypes.func.isRequired,
    setSelectedRow: PropTypes.func.isRequired,
    disableRemove: PropTypes.bool
};

export default TableCellRemove;
