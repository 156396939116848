import PropTypes from 'prop-types';
import { Button, Grid, Stack } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';

// eslint-disable-next-line react/prop-types
function Stepperaction({ lastStep, handleBack, setErrorIndex = () => {}, backClass = '', nextClass = '' }) {
    return (
        <Grid item xs={12}>
            <Stack direction="row" justifyContent={handleBack ? 'space-between' : 'flex-end'}>
                {handleBack && (
                    <Button onClick={handleBack} sx={{ my: 3, ml: 1 }} className={backClass}>
                        Back
                    </Button>
                )}

                <AnimateButton>
                    <Button className={nextClass} variant="contained" type="submit" sx={{ my: 3, ml: 1 }} onClick={setErrorIndex}>
                        {lastStep ? 'Save' : 'Next'}
                    </Button>
                </AnimateButton>
            </Stack>
        </Grid>
    );
}

Stepperaction.propTypes = {
    handleBack: PropTypes.func,
    setErrorIndex: PropTypes.func,
    backClass: PropTypes.string,
    nextClass: PropTypes.string
};

export default Stepperaction;
