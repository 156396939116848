/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
import { useSelector } from 'react-redux';
import SelectLabel from 'ui-component/GenericComponents/SelectLabel';

const Pagination = (props) => {
    const rtlLayout = useSelector((state) => state.customization.rtlLayout);
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        className,
        pageOptions
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // if (currentPage === 0 || paginationRange.length < 2) {
    //     return null;
    // }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = 0;
    if (paginationRange) lastPage = paginationRange[paginationRange.length - 1];

    return (
        // eslint-disable-next-line react/jsx-no-comment-textnodes
        <div
            className="MuiTablePagination-root css-19y622x-MuiTablePagination-root"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
            <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular MuiTablePagination-toolbar css-teccuw-MuiToolbar-root-MuiTablePagination-toolbar">
                <ul className={classnames('pagination-container', { [className]: className })}>
                    <li
                        className="pagination-item"
                        // onClick={onPrevious}
                    >
                        <SelectLabel
                            id="rows_pagination"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(e.target.value);
                                setCurrentPage(1);
                            }}
                            option={pageOptions}
                            style={{ width: '100px', textAlign: 'center', height: '20px' }}
                        />
                    </li>
                    <li
                        className={classnames('pagination-item', {
                            disabled: currentPage === 1
                        })}
                        onClick={onPrevious}
                    >
                        <div className={rtlLayout ? 'arrow right' : 'arrow left'} />
                    </li>
                    {paginationRange &&
                        paginationRange.map((pageNumber, index) => {
                            if (pageNumber === DOTS) {
                                return (
                                    <li key={index} className="pagination-item dots">
                                        &#8230;
                                    </li>
                                );
                            }

                            return (
                                <li
                                    key={index}
                                    className={classnames('pagination-item', {
                                        selected: pageNumber === currentPage
                                    })}
                                    onClick={() => onPageChange(pageNumber)}
                                >
                                    {pageNumber}
                                </li>
                            );
                        })}
                    <li
                        className={classnames('pagination-item', {
                            disabled: currentPage === lastPage
                        })}
                        onClick={onNext}
                    >
                        <div className={rtlLayout ? 'arrow left' : 'arrow right'} />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;
