import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

const TableCellAdd = ({ toolTipAdd, disableAdd, row, handleClickOpenDialog, setSelectedRow }) => {
    const theme = useTheme();
    return (
        <TableCell align="center" sx={{ p: 0 }} style={{ textAlign: 'center' }} size="small">
            {!disableAdd &&
                (!row.disable_add ? (
                    <Tooltip title={toolTipAdd ? <FormattedMessage id={toolTipAdd} /> : 'Add'}>
                        <IconButton
                            style={{ color: theme.palette.primary.main }}
                            size="small"
                            disabled={row.disable_add}
                            onClick={() => {
                                handleClickOpenDialog('add');
                                setSelectedRow(row);
                            }}
                        >
                            <RemoveRedEyeIcon sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <IconButton
                        style={{ color: theme.palette.primary.light }}
                        size="small"
                        disabled={row.disable_add}
                        onClick={() => {
                            handleClickOpenDialog('add');
                            setSelectedRow(row);
                        }}
                    >
                        <RemoveRedEyeIcon sx={{ fontSize: '1.3rem' }} />
                    </IconButton>
                ))}
        </TableCell>
    );
};

TableCellAdd.propTypes = {
    row: PropTypes.object.isRequired,
    handleClickOpenDialog: PropTypes.func.isRequired,
    setSelectedRow: PropTypes.func.isRequired,
    disableAdd: PropTypes.bool,
    toolTipAdd: PropTypes.string
};

export default TableCellAdd;
