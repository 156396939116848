/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import StepperCust from './StepperCust';
// project imports

function getStepContent(steps, activeStep, handleNext, handleBack, setErrorIndex, rest) {
    const Component = steps[activeStep].component;
    return (
        <Component
            handleNext={handleNext}
            handleBack={handleBack}
            setErrorIndex={setErrorIndex}
            state={steps[activeStep].state}
            setState={steps[activeStep].setState}
            {...rest}
        />
    );
}

// ==============================|| FORMS StepperWizard  ||============================== //

const StepperWizard = ({ steps, title, ...rest }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [errorIndex, setErrorIndex] = useState(null);
    const [stepsArray, setStepsArray] = useState([]);

    useMemo(() => {
        setStepsArray(steps);
    }, [steps]);

    const handleNext = (updatedState) => {
        console.log('handleNext');
        if (document.querySelectorAll("[aria-invalid='true']").length > 0) {
            console.log('handleNext aria-invalid=true');
            return;
        }
        setErrorIndex(null);
        console.log('handleNext after setErrorIndex');
        // eslint-disable-next-line react/prop-types
        if (stepsArray.length - 1 === activeStep) {
            // eslint-disable-next-line react/prop-types
            const onSave = stepsArray[activeStep].onSave;
            if (onSave) onSave(updatedState);
        } else setActiveStep(activeStep + 1);

        // eslint-disable-next-line react/prop-types
        const onNext = stepsArray[activeStep].onNext;
        if (onNext) onNext(updatedState);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const stepsTemp = [];
    // eslint-disable-next-line react/prop-types
    stepsArray.forEach((e) => {
        stepsTemp.push(e.title);
    });

    return (
        <Grid container spacing={gridSpacing} justifyContent="center">
            <Grid item xs={12} md={9} lg={12}>
                <MainCard title={title}>
                    <StepperCust key={activeStep} steps={stepsTemp} activeStep={activeStep} errorIndex={errorIndex} />
                    {stepsArray.length > 1 && getStepContent(stepsArray, activeStep, handleNext, handleBack, setErrorIndex, rest)}
                </MainCard>
            </Grid>
        </Grid>
    );
};

StepperWizard.propTypes = {
    steps: PropTypes.array,
    title: PropTypes.string
};

export default StepperWizard;
