/* eslint-disable prettier/prettier */
import { Table, TableContainer } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import MainCard from 'ui-component/cards/MainCard';
import DialogCust from 'ui-component/GenericComponents/DialogCust';
import { Get } from 'helper/axiosHelper';
import TableBodyCust from './Body/TableBodyCust';
import DeleteRecord from './DeleteRecord';
import TableFilter from './Filter/TableFilter';
import TableHeaders from './Header/TableHeaders';
import Pagination from './Pagination/Pagination';
import TableToolbar from './Toolbar/TableToolbar';
import { getComponent, getComponentTitle } from './TableFunc';

// ==============================|| Table list Component ||============================== //

const TableList = ({
    api,
    columns,
    readOnly,
    reportTitle = '',
    sorting,
    sortingBy,
    DialogComponentEdit,
    DialogComponentAdd,
    dialogWin = [{ DialogComp: '', toolTipWin: '', winTitle: '', iconWin: '' }],
    dialogObj = {
        winTitleAction: '',
        winTitleAdd: '',
        winTitle: '',
        winTitleDelete: '',
        message: '',
        api: '',
        pk: '',
        saveLabel: '',
        cancelLabel: '',
        delLabel: '',
        saveColor: '',
        cancelColor: '',
        delColor: ''
    },
    disableRemove,
    disableAdd,
    disableEdit,
    toolTipAdd,
    filter,
    hideToolbarHeader = false,
    hideToolbarFooter = false,
    data,
    setData,
    setSearchField,
    noRepeatAction,
    ...rest
}) => {
    const theme = useTheme();
    const [dispNone, setDispNone] = useState('');
    const [pageSize, setPageSize] = useState(100);
    const [pageOptions, setPageOptions] = useState([]);

    const [order, setOrder] = useState(sorting || 'asc');
    const [orderBy, setOrderBy] = useState(sortingBy || '');
    const [orderByType, setOrderByType] = useState(columns[0].type);

    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [apiMain, setApiMain] = useState(api);

    const [dialogObject, setDialogObject] = useState({});

    const getMainData = () => {
        if (search.length > 0) setCurrentPage(1);
        const tempPage = search.length > 0 ? -1 : currentPage;
        if (apiMain) {
            Get(`${apiMain}&page=${tempPage}&pageSize=${pageSize}&filter=${search}`)
                .then((response) => {
                    const res = response.data.data;

                    if (res) {
                        setRows(res);
                        setTotalData(response.data.totalCount);
                        if (setData) {
                            setData(res);
                        }
                    }
                })
                .catch((err) => {
                    console.log('err in table list component', err);
                });
        }
    };

    useEffect(() => {
        setDialogObject(
            dialogObj || {
                winTitleAction: '',
                winTitleAdd: '',
                winTitle: '',
                winTitleDelete: '',
                message: '',
                api: '',
                pk: '',
                saveLabel: '',
                cancelLabel: '',
                delLabel: '',
                saveColor: '',
                cancelColor: '',
                delColor: ''
            }
        );
    }, []);

    useEffect(() => {
        setApiMain(api);
    }, [api]);

    useEffect(() => {
        if (data) {
            setRows(data);
            setTotalData(data.length);
            if (setSearchField) setSearchField(search);
        } else getMainData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiMain, pageSize, currentPage, search, data]);

    useEffect(() => {
        // setRows(data);
        const pagesTemp = [
            { data: 100, display: '100' },
            { data: 500, display: '500' },
            { data: 1000, display: '1000' },
            { data: 2000, display: '2000' }
        ];
        if (totalData) pagesTemp.push({ data: totalData, display: 'All' });
        setPageOptions(pagesTemp);
    }, [totalData]);

    useEffect(() => {
        if (sortingBy && columns.length > 0) {
            const temp = columns.filter((el) => el.id === sortingBy)[0].type;
            setOrderByType(temp?.temp || columns[0].type);
        }
    }, [columns, sortingBy]);

    useEffect(() => {
        dialogObject.winTitle = dialogObject.winTitle || reportTitle;
    }, [dialogObject, reportTitle]);

    const handleSearch = (event) => {
        const newString = event?.target.value;
        setSearch(newString || '');
    };

    const handleRequestSort = (event, property, type) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setOrderByType(type);
    };

    // open dialog to edit review
    const [open, setOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState('edit');

    const handleClickOpenDialog = (action) => {
        setDialogAction(typeof action !== 'undefined' ? action : 'edit');
        setOpen(open || true);
    };
    const componentRef = useRef();
    const displayPrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: reportTitle
    });
    const handlePrint = async () => {
        await setDispNone('none');
        displayPrint();
        setDispNone('');
    };

    return (
        <>
            {filter && (
                <TableFilter
                    setApiMain={setApiMain}
                    mainApi={api}
                    filterObj={filter.filterObj}
                    defFilter={filter.defFilter}
                    retrieveData={filter.retrieveData}
                />
            )}
            <MainCard title="" content={false}>
                {!hideToolbarHeader && (
                    <TableToolbar
                        data={data}
                        handleSearch={handleSearch}
                        search={search}
                        columns={columns}
                        reportTitle={reportTitle}
                        handlePrint={handlePrint}
                        handleClickOpenDialog={handleClickOpenDialog}
                        setSelectedRow={setSelectedRow}
                        api={api}
                        totalData={totalData}
                        {...rest}
                    />
                )}
                {/* table */}
                <TableContainer size="small" aria-label="a dense table">
                    <Table aria-labelledby="tableTitle" ref={componentRef}>
                        <TableHeaders
                            columns={columns}
                            theme={theme}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            readOnly={readOnly}
                            disableRemove={disableRemove}
                            disableEdit={disableEdit}
                            disableAdd={disableAdd}
                            dispNone={dispNone}
                        />
                        {rows && (
                            <TableBodyCust
                                columns={columns}
                                rows={rows}
                                order={order}
                                orderBy={orderBy}
                                orderByType={orderByType}
                                theme={theme}
                                readOnly={readOnly}
                                handleClickOpenDialog={handleClickOpenDialog}
                                setSelectedRow={setSelectedRow}
                                currentPage={currentPage}
                                pageSize={pageSize}
                                disableRemove={disableRemove}
                                disableAdd={disableAdd}
                                disableEdit={disableEdit}
                                toolTipAdd={toolTipAdd}
                                dispNone={dispNone}
                                DialogComponentAdd={DialogComponentAdd}
                                dialogWin={dialogWin}
                                noRepeatAction={noRepeatAction}
                            />
                        )}
                        {/* edit dialog */}

                        <DialogCust
                            showDialog={open}
                            DialogComponent={getComponent(dialogAction, DialogComponentEdit, DialogComponentAdd, dialogWin, DeleteRecord)}
                            dialogObj={getComponentTitle(dialogAction, dialogObject, dialogWin)}
                            onClose={() => {
                                console.log('table ;ist ');
                                setOpen(false);
                            }}
                            row={{ ...selectedRow }}
                            forceRefresh={getMainData}
                            {...rest}
                        />
                    </Table>
                </TableContainer>

                {/* table pagination */}
                {!hideToolbarFooter && (
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalData}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                        pageOptions={pageOptions}
                        setCurrentPage={setCurrentPage}
                    />
                )}
            </MainCard>
        </>
    );
};

TableList.propTypes = {
    columns: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired,
    reportTitle: PropTypes.string.isRequired,
    sorting: PropTypes.string.isRequired,
    sortingBy: PropTypes.string,
    DialogComponentEdit: PropTypes.func.isRequired,
    DialogComponentAdd: PropTypes.func.isRequired,
    dialogObj: PropTypes.object.isRequired,
    disableRemove: PropTypes.bool,
    disableEdit: PropTypes.bool,
    disableAdd: PropTypes.bool,
    toolTipAdd: PropTypes.string,
    hideToolbarHeader: PropTypes.bool,
    hideToolbarFooter: PropTypes.bool,
    dialogWin: PropTypes.array,
    api: PropTypes.string,
    filter: PropTypes.object,
    data: PropTypes.array,
    setData: PropTypes.func,
    setSearchField: PropTypes.func,
    noRepeatAction: PropTypes.bool
};

export default TableList;
