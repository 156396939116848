/* eslint-disable prettier/prettier */
import SelectLabel from 'ui-component/GenericComponents/SelectLabel';
import DatePickerCust from 'ui-component/GenericComponents/DatePickerCust';
import TextFieldCust from 'ui-component/GenericComponents/TextFieldCust';
import SelectAutoCompleteAsync from 'ui-component/GenericComponents/SelectAutoCompleteAsync';

// eslint-disable-next-line consistent-return
const getComponent = (definition, filter, changeInfo) => {
	// eslint-disable-next-line default-case
	switch (definition.component) {
		case 'select':
			return (
				<SelectLabel
					id={definition.field}
					name={definition.name}
					value={filter[definition.name]}
					onChange={async (e) => {
						await changeInfo(e);
						if (definition.onChange) definition.onChange(e, filter);
					}}
					option={definition.options}
					dddw={definition.dddw}
					data={definition.data}
					display={definition.display}
				/>
			);
		case 'autoComplete':
			return (
				<SelectAutoCompleteAsync
					id={definition.field}
					name={definition.name}
					value={filter[definition.name]}
					onChange={(e) => {
						changeInfo(e);
						if (definition.onChange) definition.onChange(e, filter);
					}}
					option={definition.options}
					data={definition.data}
					display={definition.display}
					dddw={definition.dddw}
				/>
			);
		case 'input':
			return (
				<TextFieldCust
					id={definition.field}
					name={definition.name}
					value={filter[definition.name]}
					onChange={(e) => {
						changeInfo(e);
						if (definition.onChange) definition.onChange(e, filter);
					}}
				/>
			);
		case 'date':
			return (
				<DatePickerCust
					id={definition.field}
					name={definition.name}
					value={filter[definition.name]}
					onChange={(e) => {
						changeInfo(e);
						if (definition.onChange) definition.onChange(e, filter);
					}}
				/>
			);
	}
};

export default getComponent;
