// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    title: 'Main',
    type: 'group',
    children: [
        {
            id: 'homes-page',
            title: <FormattedMessage id="homes-page" />,
            type: 'item',
            url: '/',
            breadcrumbs: true
        },

        {
            id: 'translations-page',
            title: <FormattedMessage id="translations-page" />,
            type: 'item',
            url: '/translations',
            breadcrumbs: true
        }
    ]
};

export default other;
