/* eslint-disable prettier/prettier */
import { Get } from 'helper/axiosHelper';
import { useEffect, useState } from 'react';
import TableList from 'ui-component/TableList';
import SummaryInfo from './StudentSummary/SummaryInfo';
import UpsertStudents from './UpsertStudents';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { bloodTypes, gender, governorates } from '../../../Ddl';

// table header options
const columns = [
    {
        id: 'id',
        numeric: true,
        label: 'id',
        width: '0%',
        hidden: true
    },
    {
        id: 'username',
        numeric: false,
        label: 'username',
        width: '15%'
    },
    {
        id: 'student_full_name',
        numeric: false,
        label: 'student_full_name',
        width: '30%'
    },
    {
        id: 'class_name',
        numeric: false,
        label: 'class_name',
        width: '25%'
    },
    {
        id: 'section_full_name',
        numeric: false,
        label: 'section_full_name',
        width: '25%'
    },
    {
        id: 'student_status',
        numeric: false,
        width: '5%',
        type: 'button',
        success: 'Active'
    }
];

// ==============================|| Students PAGE ||============================== //

const StudentsPage = () => {
    const [nationality, setNationality] = useState([]);
    const [counties, setCounties] = useState([]);
    const [areas, setAreas] = useState([]);
    const [branches, setBranches] = useState([]);
    const [parents, setParents] = useState([]);

    const [academic, setAcademic] = useState([]);
    const [classes, setClasses] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedYear, setSelectedYear] = useState(0);
    const [defFilter, setDefFilter] = useState({ branch_id: 0, academic_year_id: selectedYear, class_id: 0, section_id: 0 });

    const getClasses = (academic) => {
        Get(`classes/get_all?academic_year_id=${academic.target.value}&cycle_id=undefined`).then((res) => {
            if (res.data.data) {
                setClasses(res.data.data);
                setDefFilter((prev) => ({ ...prev, academic_year_id: academic.target.value }));
            }
        });
    };

    const getSections = (e, filter) => {
        Get(`sections/get_all?class_id=${e.target.value}&branch_id=${filter.branch_id}`).then((res) => {
            if (res.data.data) {
                setSections(res.data.data);
            }
        });
    };

    const filterObj = [
        {
            field: 'branch_name',
            name: 'branch_id',
            component: 'select',
            options: branches,
            data: 'id',
            display: 'branch_name'
        },
        {
            field: 'academic_name',
            name: 'academic_year_id',
            component: 'select',
            dddw: 'academic_years/get_all_without_relations',
            data: 'id',
            display: 'academic_year_name',
            onChange: getClasses
        },
        {
            field: 'class',
            name: 'class_id',
            component: 'select',
            options: classes,
            data: 'id',
            display: 'class_name',
            onChange: getSections
        },
        {
            field: 'section',
            name: 'section_id',
            component: 'select',
            options: sections,
            data: 'id',
            display: 'section_full_name'
        }
    ];

    // This effect runs once, after the first render
    useEffect(() => {
        document.title = 'SIS(Students List)';
        Get('countries/get_all').then((res) => {
            if (res.data.data) {
                setNationality(res.data.data);
            }
        });
        Get('counties/get_all').then((res) => {
            if (res.data.data) {
                setCounties(res.data.data);
            }
        });
        Get('areas/get_all').then((res) => {
            if (res.data.data) {
                setAreas(res.data.data);
            }
        });
        Get('branches/get_all').then((res) => {
            if (res.data.data) {
                setBranches(res.data.data);
            }
        });
        Get('parents/get_all').then((res) => {
            if (res.data.data) {
                setParents(res.data.data);
            }
        });
        Get('academic_years/get_all_without_relations?page=1&pageSize=50&filter=').then((res) => {
            if (res.data.data) {
                setAcademic(res.data.data);
            }
        });
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <TableList
                api="students/get?"
                api_export="students/get_all"
                columns={columns}
                reportTitle="students_list"
                sorting="asc"
                DialogComponentEdit={UpsertStudents}
                dialogObj={{
                    winTitle: 'student_list',
                    api: 'students/delete',
                    pk: 'id'
                }}
                dialogWin={[
                    {
                        DialogComp: SummaryInfo,
                        toolTipWin: 'student_summary',
                        winTitle: 'student_summary',
                        iconWin: SummarizeIcon
                    }
                ]}
                filter={{
                    filterObj,
                    defFilter
                }}
                disableRemove
                nationality={nationality}
                counties={counties}
                areas={areas}
                branches={branches}
                parents={parents}
                genderOpt={gender}
                bloodTypes={bloodTypes}
                academic={academic}
                governorates={governorates}
            />
        </div>
    );
};
export default StudentsPage;
