/* eslint-disable prettier/prettier */
/* eslint-disable consistent-return */
export const getComponent = (action, DialogComponentEdit, DialogComponentAdd, dialogWin, DeleteRecord) => {
    if (action === 'edit' || action === 'new') return DialogComponentEdit;
    if (action === 'add') return DialogComponentAdd;
    if (action === 'delete') return DeleteRecord;
    return dialogWin[action].DialogComp;
};

export const getComponentTitle = (action, dialogObj = {}, dialogWin) => {
    if (action === 'edit' || action === 'add' || action === 'new')
        return { ...dialogObj, winTitleAction: action === 'edit' ? 'Edit' : 'Add' };
    if (action === 'delete') return { ...dialogObj, winTitleAction: 'Delete' };
    return { ...dialogObj, winTitle: typeof dialogWin[action].winTitle !== 'undefined' ? dialogWin[action].winTitle : 'Title' };
};
