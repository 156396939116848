import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import StudentsPage from 'views/UserManagement/Students';

// sample page routing
const Buildings = Loadable(lazy(() => import('views/Definitions/generalDefinitions/buildings')));
const AcademicYearsPage = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/academic-years')));
const TranslationPage = Loadable(lazy(() => import('views/translations')));
const BranchesPage = Loadable(lazy(() => import('views/Definitions/generalDefinitions/branches')));
const HomePage = Loadable(lazy(() => import('views/home')));
const AbsenceTypesPage = Loadable(lazy(() => import('views/Definitions/generalDefinitions/absenceTypes')));
const EvaluationTypesPage = Loadable(lazy(() => import('views/Definitions/generalDefinitions/evaluationTypesConfig')));
const ExamConfigrationsPage = Loadable(lazy(() => import('views/Definitions/generalDefinitions/examConfig')));
const CardConfigrationsPage = Loadable(lazy(() => import('views/Definitions/generalDefinitions/cardConfig')));
const RoomsPage = Loadable(lazy(() => import('views/Definitions/generalDefinitions/rooms')));
const MenuPagesPage = Loadable(lazy(() => import('views/Definitions/generalDefinitions/pages')));
const CyclesPage = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/cycles')));
const ClassesPage = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/classes')));
const ParentsPage = Loadable(lazy(() => import('views/UserManagement/parents')));
const SubjectsList = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/Subjects')));
const Configurations = Loadable(lazy(() => import('views/Definitions/generalDefinitions/configurations/Configurations')));
const FunctionalCat = Loadable(lazy(() => import('views/UserManagement/FunctionalCat')));
const Staff = Loadable(lazy(() => import('views/UserManagement/Staff')));
const Administrative = Loadable(lazy(() => import('views/UserManagement/Administrative')));
const FunctionalCatAssign = Loadable(lazy(() => import('views/UserManagement/FunctionalCatAssign')));
const AssignSections = Loadable(lazy(() => import('views/UserManagement/AssignSections')));
const Users = Loadable(lazy(() => import('views/UserManagement/Users')));
const Roles = Loadable(lazy(() => import('views/UserManagement/Roles')));
const DeletedStaff = Loadable(lazy(() => import('views/UserManagement/Staff/DeletedStaff')));
const AssignClassesTeachers = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/AssignClassesTeachers')));
const AssignTeachersSubjects = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/AssignTeachersSubjects')));
const AssignCoordinatorsSubjects = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/AssignCoordinatorsSubjects')));
const AssignSupervisors = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/AssignSupervisors')));
const AssignCycleDirectors = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/AssignCycleDirectors')));
const AssignSectionsClasses = Loadable(lazy(() => import('views/Definitions/AcademicDefinitions/AssignSectionsClasses')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/branches',
            element: <BranchesPage />
        },
        {
            path: '/buildings',
            element: <Buildings />
        },
        {
            path: '/academic-years',
            element: <AcademicYearsPage />
        },
        {
            path: '/translations',
            element: <TranslationPage />,
            title: 'Transaltion'
        },
        {
            path: '/absence-types',
            element: <AbsenceTypesPage />
        },
        {
            path: '/evaluation-types',
            element: <EvaluationTypesPage />
        },
        {
            path: '/exam-configrations',
            element: <ExamConfigrationsPage />
        },
        {
            path: '/card-configurations',
            element: <CardConfigrationsPage />
        },
        {
            path: '/rooms',
            element: <RoomsPage />
        },
        {
            path: '/pages',
            element: <MenuPagesPage />
        },
        {
            path: '/cycles',
            element: <CyclesPage />
        },
        {
            path: '/configrations',
            element: <Configurations />
        },
        {
            path: '/classes',
            element: <ClassesPage />
        },
        {
            path: '/students',
            element: <StudentsPage />
        },
        {
            path: '/parents',
            element: <ParentsPage />
        },
        {
            path: '/subjects',
            element: <SubjectsList />
        },
        {
            path: '/functional-categories',
            element: <FunctionalCat />
        },
        {
            path: '/staff',
            element: <Staff />
        },
        {
            path: '/administrative-hierarchy',
            element: <Administrative />
        },
        {
            path: '/assign-functional-categories',
            element: <FunctionalCatAssign />
        },
        {
            path: '/assign-sections',
            element: <AssignSections />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/roles',
            element: <Roles />
        },
        {
            path: '/deletedStaff',
            element: <DeletedStaff />
        },
        {
            path: '/classesTeachers',
            element: <AssignClassesTeachers />
        },
        {
            path: '/TeachersSubjects',
            element: <AssignTeachersSubjects />
        },
        {
            path: '/coordinatorsSubjects',
            element: <AssignCoordinatorsSubjects />
        },
        {
            path: '/assignSupervisors',
            element: <AssignSupervisors />
        },
        {
            path: '/assignCycleDirectors',
            element: <AssignCycleDirectors />
        },
        {
            path: '/sectionsclasses',
            element: <AssignSectionsClasses />
        }
    ]
};

export default MainRoutes;
